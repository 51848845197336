import { Model } from '@vuex-orm/core';
import UNIT from 'Units/constant';
import mapTranslations from 'Units/utils/mapTranslations';
import UnitsService from 'Units/services/units.service';
import User from 'Auth/models/User';
import log from 'Core/services/log.service';
// import i18n from 'Core/services/language.service';
import BluetoothService from 'Core/services/bluetooth.service';
import { DataInterface } from '@airzone/data-interface';
import bleToModel from 'Units/interfaces/bleToModel.interface';
import { hasProp } from 'Core/utils/validate.utils';
import AppError from 'Core/services/errors.service';
import moment from 'moment-timezone';
import { getModeStringValue } from 'Units/utils/mode.utils';
import cloud2web from 'Units/interfaces/cloud2web.interface';
import ReportsService from 'Units/services/reports.service';
import Schedule from './Schedule.model';
import ScheduleConf from './ScheduleConf.model';
import Group from './Group.model';
import { System, ACS, Zone, VMC, Relay, CCP, Clamp, Purifier, Outputs, Dehumidifier} from './DeviceHierarchy';
import store from 'Core/store/store';

/**
 * Modelo de dispositivo que puede integrar cualquier sistema.
 * Puede ser desde una zona, purificador, acs, aidoo, etc...
 *
 * @param {String} id - identificador único
 * @param {String} installation_id - identificador de la instalación a la que pertenece
 * @param {boolean} loadedData - indica si los datos se han cargado en el modelo
 * @param {String} system_id - identificador del sistema al que pertenece
 * @param {String} type - Tipo de dispositivo: ZONE, ACS, PURIFIER, CLAMP, etc...
 * @param {String} webserver_id - identificador del webserver al que pertenece
 * @param {String} zone_number - zona a la que pertenece
 * @param {String} isChanging - parámetro para controlar si está siendo modificado algún campo sensible para su control. Ej: modo de las Zonas.
 * @param {Array} buttons_order - Guarda un array de datos que le enviemos. Lo usamos para guardar el orden de los items del menú de opciones de una Zona, ACS, etc.
 * @param {Array} num_visible_buttons - Indica el número de botones que se mostrá en la vista del dispositivo (incluído el botón "more")
 */
export class Device extends Model {
  static entity = 'devices';

  static types() {
    return {
      DEVICE: Device,
      ZONE: Zone,
      CLAMP: Clamp,
      PURIFIER: Purifier,
      SYSTEM: System,
      OUTPUTS: Outputs,
      RELAY: Relay,
      VMC,
      ACS,
      CCP,
      DEHUMIDIFIER: Dehumidifier,
    };
  }

  /** *************************************************************
   * CAMPOS
   ************************************************************** */
  static fields() {
    return {
      id: this.attr(null),
      group_id: this.attr(null),
      installation_id: this.attr(null),
      loadedData: this.boolean(false),
      system_number: this.number(undefined).nullable(),
      type: this.attr(UNIT.MODEL_TYPE.DEVICE),
      webserver_id: this.attr(null),
      zone_number: this.number(0).nullable(),
      isChanging: this.boolean(false),
      linked_zone_name: this.string('').nullable(),
      device_type: this.string(undefined).nullable(),
      device_semantic_type: this.string(undefined).nullable(),
      device_status: this.attr(undefined).nullable(),
      config: this.attr(undefined).nullable(),
      // schedule_conf: this.attr(undefined).nullable(),
      // schedules: this.attr(undefined).nullable(),
      macBLE: this.string(undefined).nullable(),
      scheduleConf: this.hasOne(ScheduleConf, 'device_id'),
      schedules: this.hasMany(Schedule, 'device_id'),
      backendModel: this.attr(undefined).nullable(),
      bleModel: this.attr(undefined).nullable(),
      warnings: this.attr(undefined).nullable(),
      errors: this.attr(undefined).nullable(),
      machine_units: this.attr(undefined).nullable(),
      machineready: this.boolean(undefined).nullable(),
      isConnected: this.boolean(undefined).nullable(),
      position: this.number(undefined).nullable(),
      acs_sched_available: this.boolean(undefined).nullable(),
      ws_sched_available: this.boolean(undefined).nullable(),
      ws_sched_calendar_available: this.boolean(undefined).nullable(),
      ws_sched_param_indep: this.boolean(undefined).nullable(),
      wizard_available: this.boolean(undefined).nullable(),
      name_editable: this.boolean(undefined).nullable(),
      success: this.boolean(false).nullable(),
      radio_batt_percent: this.number(undefined).nullable(),
      radio_coverage_percent: this.number(undefined).nullable(),
      buttons_order: this.attr(undefined),
      num_visible_buttons: this.number(undefined),
      actual_energy_gen_heat: this.attr(undefined).nullable(),
      actual_energy_gen_cool: this.attr(undefined).nullable(),
      actual_energy_gen_acs: this.attr(undefined).nullable(),
      actual_energy_gen_pv: this.attr(undefined).nullable(),
      actual_consumpt_hp: this.attr(undefined).nullable(),
      actual_consumpt_total_building: this.attr(undefined).nullable(),
      consumpt_electric_heat_resistor: this.attr(undefined).nullable(),
      consumpt_electric_acs_resistor: this.attr(undefined).nullable(),
      consumpt_comp_heat: this.attr(undefined).nullable(),
      consumpt_comp_cool: this.attr(undefined).nullable(),
      consumpt_comp_acs: this.attr(undefined).nullable(),
      total_energy_consumpt: this.attr(undefined).nullable(),
      total_energy_gen_heat: this.attr(undefined).nullable(),
      total_energy_gen_cool: this.attr(undefined).nullable(),
      total_energy_gen_acs: this.attr(undefined).nullable(),
      total_energy_produced: this.attr(undefined).nullable(),
      total_consumpt_hp: this.attr(undefined).nullable(),
      manufacturer: this.attr(undefined).nullable(),
      manuf_allows_local_temp_ee: this.boolean(undefined).nullable(),
      manuf_working_local_temp_ee: this.boolean(undefined).nullable(),
      local_temp_ee: this.number(undefined).nullable(),
      ws_connected: this.boolean(undefined).nullable(),
      data_sharing_enabled: this.boolean(undefined).nullable(),
      r32_alarm_active: this.boolean(undefined).nullable(),
      r32_alarm_readonly: this.boolean(undefined).nullable(),
    };
  }

  hasConsumptionMeasurements = () => {
    return this.total_consumpt_hp !== undefined && this.total_consumpt_hp !== null ||
        this.actual_consumpt_hp !== undefined && this.actual_consumpt_hp !== null ||
        this.total_energy_gen_heat !== undefined && this.total_energy_gen_heat !== null ||
        this.actual_energy_gen_heat !== undefined && this.actual_energy_gen_heat !== null ||
        this.total_energy_gen_cool !== undefined && this.total_energy_gen_cool !== null ||
        this.actual_energy_gen_cool !== undefined && this.actual_energy_gen_cool !== null ||
        this.total_energy_gen_acs !== undefined && this.total_energy_gen_acs !== null ||
        this.actual_energy_gen_acs !== undefined && this.actual_energy_gen_acs !== null ||
        this.actual_energy_gen_pv !== undefined && this.actual_energy_gen_pv !== null ||
        this.actual_consumpt_total_building !== undefined && this.actual_consumpt_total_building !== null ||
        this.consumpt_electric_heat_resistor !== undefined && this.consumpt_electric_heat_resistor !== null ||
        this.consumpt_electric_acs_resistor !== undefined && this.consumpt_electric_acs_resistor !== null ||
        this.consumpt_comp_heat !== undefined && this.consumpt_comp_heat !== null ||
        this.consumpt_comp_cool !== undefined && this.consumpt_comp_cool !== null ||
        this.consumpt_comp_acs !== undefined && this.consumpt_comp_acs !== null ||
        this.total_energy_produced !== undefined && this.total_energy_produced !== null ||
        this.total_energy_consumpt !== undefined && this.total_energy_consumpt !== null
  }
  /** *************************************************************
   * ACCIONES
   ************************************************************** */

  get getError() {
    //
    // Mientras no se han recibido los datos no se muestra errores
    //
    if (!this.loadedData) return null;
    //
    // El Webserver no tiene conexión a internet
    //
    if(this.ws_connected === false) {
      return 'webserverNotConnected';
    }

    //
    // La zona ha perdido la conexión con el sistema (termostato sin pilas, cable desconectado, conexión radio perdida, etc...)
    //
    if (!this.isConnected) {
      return 'notConnected';
    }

    //
    // En "Aidoo", la máquina exterior no está conectada
    //
    if (this.machineready === false) {
      return 'machineNotReady';
    }

    return null;
  }

  /**
   * Actualiza un parámetro de una zona (SÓLO EN EL MODELO: Sin enviarlo al servicio)
   *
   * @param {String} param - El parámetro a actualizar
   * @param {String,Number} value - El nuevo valor {param: value}
   */
  setParam = async (param, value) => {
    const deviceID = this.id;
    const deviceData = {};
    deviceData[param] = value;

    await Device.update({
      where: deviceID,
      data: deviceData,
    });
    log.success(`Editado ${param}`);
  };

  setDeviceStatus = async (params, opts, isBle = false) => {
    const installationID = this.installation_id;
    const deviceID = this.id;

    if(!store.getters.getIsDemo) {
      await this.setParam('isChanging',true);

      if(!isBle) {
        try {
          await UnitsService.setDeviceStatusV2(deviceID, installationID, params, opts);
        } catch(err) {
          await this.setParam('isChanging', false);
          await this.setParam('isChangingMode', false);
          throw new Error(err);
        }
      } else {
        // ///////////////////////////////////////////////////////////
        // BLE mode: Actualizamos los parámetros a través de bluetooth
        // ///////////////////////////////////////////////////////////
        console.log("Llamando a setStatus en BLE");
        const device = Device.find(deviceID);
        const data = await this.sendBleStatus(device, params, null, opts)

        await Device.update({data});

        await this.setParam('isChanging', false);
        await this.setParam('isChangingMode', false);
      }
    } else if (this instanceof Zone) {

      // En modo demo, si la zona pasa a ser Master, garantizamos que el resto de las zonas
      // del sistema no tengan el estado Master.
      if (params.master_conf) {
        Zone.query().where('system_number', this.system_number).get().forEach(zone => {
          if (zone.$id === deviceID) return;
          zone.setDeviceStatus({ master_conf: false }, null, !this.fromAirtools)
        })
      }
    }

    const deviceData = {};

    Object.keys(params).forEach(param => {
      if(param === 'mode') {
        deviceData[param] = typeof params[param] === 'number' ?  getModeStringValue(params[param]) : params[param];
      } else {
        deviceData[param] = params[param]
      }
    })

    await Device.update({
      where: deviceID,
      data: deviceData,
    });
    log.success(`Editado ${JSON.stringify(params)}`);

  }

  /**
   * Actualiza un parámetro de un dispositivo y lo envía al backend
   *
   * @todo Actualizar los parámetros nuevos
   * @param {String} param - El parámetro a actualizar
   * @param {String,Number} value - El nuevo valor {param: value}
   * @param {Object} opts - El objeto con las unidades de la temperatura si {param: es temp}
   * @param {Boolean} waitingWebsocket - Booleano para controlar si el cargando lo desactiva el websocket
   */
  setStatus = async (param, value, opts, waitingWebsocket) => {
    const installationID = this.installation_id;
    const deviceID = this.id;
    if(param === 'mode') {
      if (!store.getters.getIsDemo) {
        await this.setParam('isChangingMode',true);
      // Si cambio el modo en una zona maestra de climatización del demo actualizo el modo en esclavas
      } else if (this.device_type === UNIT.DEVICE_TYPE.az_zone && this.mode_available.length > 0) {
        const zones = Device.query()
          .where('installation_id', this.installation_id)
          .where('device_type', UNIT.DEVICE_TYPE.az_zone)
          .where('system_number', this.system_number).get();

        const deviceData = {};
        deviceData[param] = value;
        zones.forEach(zone => {
          Device.update({
            where: zone.id,
            data: deviceData
          })
        })
      }
    }
    if(store.getters.getIsDemo || !this.macBLE) {
      if (!store.getters.getIsDemo) {
        await UnitsService.setDeviceStatus(deviceID, installationID, param, value, opts);
      }
      //
      // Actualizo el modelo
      //
      const deviceData = {};
      deviceData[param] = value;

      await Device.update({
        where: deviceID,
        data: deviceData,
      });
      log.info(`setDeviceStatus ${this.name}: ${JSON.stringify(deviceData)}`);

      if (!waitingWebsocket) {
        await this.setParam('isChanging', false);
        await this.setParam('isChangingMode', false);
      }

    } else {
      // ///////////////////////////////////////////////////////////
      // BLE mode: Actualizamos los parámetros a través de bluetooth
      // ///////////////////////////////////////////////////////////
      console.log("Llamando a setStatus en BLE");
      const device = Device.find(deviceID);

      const data = await this.sendBleStatus(device, param, value, opts)

      await Device.update({data});

      await this.setParam('isChanging', false);
      await this.setParam('isChangingMode', false);
    }


  };

  async setBleStatusAndRefresh(param, value, opts) {
    // ///////////////////////////////////////////////////////////
      // BLE mode: Actualizamos los parámetros a través de bluetooth
      // ///////////////////////////////////////////////////////////
      const deviceID = this.id;
      console.log("Llamando a setStatus en BLE");
      const device = Device.find(deviceID);

      const data = await this.sendBleStatus(device, param, value, opts)

      await Device.update({data});

      await this.setParam('isChanging', false);
      await this.setParam('isChangingMode', false);
  }

  async sendBleStatus(device, param, value, opts) {

    const deviceType = device.device_type;

    // const userUnits = user.getUnitsValue;
    // console.log('units: ', userUnits);
    // Preparamos el objeto de entrada fields con el parámetro y valor a actualizar
    let fields = {}

    if(typeof param === 'object') {
      fields = param
    } else {
      fields[param] = value;
    }
    // Declaramos la interfaz Web2BLE y BLE2Web
    console.log("DeviceType", this.device_type);
    let ifDevices;
    // Si es Aidoo Pro tenemos que especificarlo en la interfaz
    if(deviceType === UNIT.DEVICE_TYPE.aidoo || deviceType === UNIT.DEVICE_TYPE.aidoo_it){
      ifDevices = DataInterface.Device(UNIT.WS_TYPE.ws_aidoo, deviceType);
    } else {
      ifDevices = DataInterface.Device(UNIT.WS_TYPE.ws_az, deviceType);
    }
    //
    // Primero obtemos la conversión desde nuestros datos Web a BLE
    //
    let params = {};

    console.log('Fields: ', fields);
    console.log('backendModel: ', device.backendModel);

    // if(device?.backendModel[param] !== undefined) {
      console.log('backendModel[param]: ', device.backendModel[param]);
      if(opts) {
        console.log("opts:", opts)
        params = ifDevices.Web2BLE(fields, device.backendModel, opts, device.bleModel);
      } else {
        params = ifDevices.Web2BLE(fields, device.backendModel, undefined, device.bleModel);
      }
    // }

    //
    // Con los parámetros necesarios Ble actualizamos el dispositivo y
    // recibimos los cambios a updatedParams
    //
    console.log("params to set", params);
    const updatedParams = await this.setParamsBle(this.macBLE, params, deviceType);
    console.log("Updated params", updatedParams);

    //
    // Actualizamos nuestro modelo ble con los nuevos cambios para que esté
    // como en el webserver
    //
    if(device.bleModel === undefined || device.bleModel === null) {
      device.bleModel = {}
    }

    Object.assign(device.bleModel, {
      ...updatedParams,
    });


    console.log("Updated ble model", device.bleModelblemod);
    //
    // Los cambios realizados han podido afectar a otros campos en nuestro
    // modelo Cloud (además del propio).
    // Por ello hacemos una nueva conversión BLE2Web para
    // ver que campos en mi modelo web se han visto afectados
    //
    const ifResponse = ifDevices.BLE2Web(updatedParams, device.bleModel);

    console.log("DataInterface Response in set status", ifResponse);
    //
    // Parseamos los datos recibidos para guardarlos finalmente en mi model frontend
    //
    const user = User.query().first();
    const dataParsed = cloud2web.parseData(ifResponse.adv_conf, user.units);
    // El modelo backend será almacenado a parte, ya que es prácticamente igual que
    // el frontend, pero guarda información de las unidades en fah y celsius, así como
    // algunas difrenecias en la forma de almacenar los modos, etc.
    Object.assign(device.backendModel,ifResponse.adv_conf)
    //
    // Preparamos el objeto final que va a actualizar nuestro modelo en la vista
    //
    const data = {
      ...dataParsed,
      backendModel: device.backendModel,
      bleModel: device.bleModel,
      id: device.id
    }

    console.log("data", data);
    await bleToModel.resetDeviceBLEStatus(device.id);

    return data;
  }

  // setStatusV2 = async (params, opts, waitingWebsocket) => {

  // }

  /**
   * Actualiza metainformación de un dispositivo y lo envía al backend
   *
   * @todo Actualizar los parámetros nuevos
   * @param {String} param - El parámetro a actualizar
   * @param {String,Number} value - El nuevo valor {param: value}
   */
  setMeta = async (param,value) => {
    await UnitsService.setDeviceMeta(this.id, this.installation_id, this.group_id, param, value);

    const data = {};

    data[param] = value;

    Device.update({
      where: this.id,
      data
    });

    log.info(`setDeviceMeta ${this.id}: ${JSON.stringify(data)}`);

  }

  setDeviceButtonsOrder = async (buttonsOrder, numVisibleButtons, allZones = false) => {
    let data = null;
    if(!store.getters.getIsDemo) {
      data = await UnitsService.setDeviceButtonsOrder(this.id, this.installation_id, this.group_id, this.device_type, buttonsOrder, numVisibleButtons, allZones);
    }

    if(allZones === true && !store.getters.getIsDemo) {
      Device.update({data});
    } else {
      data = {
        buttons_order: buttonsOrder,
        num_visible_buttons: numVisibleButtons
      }

      Device.update({
        where: this.id,
        data
      });

    }

    log.info(`setButtonsOrder ${this.id}: ${JSON.stringify(data)}`);
  }

  static moveDeviceToGroup = async (installationID, oldGroupId, newGroupId, zoneID, groupName = undefined, position = undefined) => {
    let data = {};

    if(!store.getters.getIsDemo){
      data = await UnitsService.moveDeviceToGroup(installationID, oldGroupId, newGroupId, zoneID, groupName, position);

      // Actualizamos el modelo
      await Group.deleteAll();

      console.log("Agregar al modelo",data);
      await Device.insertOrUpdate({ data: data.devices})
      await Group.insertOrUpdate({data: data.groups})
    }



    log.info(`Device ${this.name} move to group ${newGroupId}`);

    return data;
  }

  /**
   *  Obtiene la información de configuración del distpositivo y la carga en el modelo
   *
   *  @param {String} deviceID - id del dispositivo
   *  @param {String} installationID - id de instalación
   *  @param {String} type - Tipo de configuración. Airtools ('advanced'), Usuario ('user'), Todas ('all')
   *  @param {String} deviceID - id del dispositivo
   *  @return {Boolean} - devuelve true si la ha cargado con éxito
   */
  static getConfig = async (deviceID, installationID, type = 'advanced') =>  {
    //
    // Le paso literal el type="advanced"
    //
    // const deviceID = this.id;
    // const installationID = this.installation_id;

    const userUnits = User.query().first().units;
    const configParams = await UnitsService.getDeviceConfig(deviceID, installationID, userUnits, type);


    console.log(deviceID, type, configParams)
    await Device.update({
      where: deviceID,
      data: configParams
    });

    return true;
  }

  /**
   * Obtiene el estado de configuración avanzada inicial del Device a través de BLE
   *
   * @param {String} deviceID
   */
  static getBleConfig = async deviceID => {
    // console.log("getBleConfig",deviceID)
    const bleModel = {};
    // console.log("Todos los devices",Device.all());
    const device = Device.find(deviceID);
    // Si no existe el device no hacemos nada;
    if(device === null) return;
    // console.log("GET PARAMS: Device in getBleConfig =======================> ", device);
    const macBLE = device.macBLE;
    // const deviceID = device.id;
    const deviceType = device.device_type;
    // console.log("device_type", deviceType);
    let ifDevices;
    if(deviceType === UNIT.DEVICE_TYPE.aidoo || deviceType === UNIT.DEVICE_TYPE.aidoo_it){
      // console.log("Creando interface aidoo_it");
      ifDevices = DataInterface.Device(UNIT.WS_TYPE.ws_aidoo, deviceType);
    } else {
      ifDevices = DataInterface.Device(UNIT.WS_TYPE.ws_az, deviceType);
    }
    const user = User.query().first();
    // const userUnits = user.getUnitsValue;
    // console.log("in getBleConfig", device, macBLE);
    //
    // Obtengo la lista de parámetros del Device
    //
    const paramList = await device.getParamsBle(macBLE);
    //
    // Obtengo la pareja parámetros valor
    //
    let params
    if(paramList.infoparams &&
      hasProp(paramList.infoparams, 'params') &&
      paramList.infoparams.params.length > 0){

      params = await device.getParamsBleValues(macBLE, paramList.infoparams.params);

      // Si es un sistema de aidoo pro, tenemos que obtener las machine_units de ahí.

      // if(device.device_type === 'aidoo' || device.device_type === UNIT.DEVICE_TYPE.aidoo_it) {
      //   device.machine_units = params.sys_confmaquina_grados
      //   await device.$save(); // Guardamos el dato en el modelo
      // }
      console.log('Params con mdbu para la interfaz', params)
    } else {
      console.log("Error obteniendo lista de parámetros");
      if(deviceType !== UNIT.DEVICE_TYPE.aidoo_it && deviceType !== UNIT.DEVICE_TYPE.aidoo){
        throw new AppError('errorGetDeviceInfo')
      } else {
        return;
      }
    }
    const utcOffset = moment().utcOffset();
    //
    // Creamos el bleModel inicial con los parámetros y agregamos las opts
    // Agregamos también información de utc_offset
    //
    Object.assign(bleModel, {
      ...params,
      units: device.machine_units,
      utc_offset: utcOffset
    });
    //
    // Obtenemos los datos correspondientes a nuestro modelo web
    //
    console.log("datos para la interfaz", params, bleModel);

    const ifResponse = ifDevices.generateBLEAdvConf({zone_id: device.zone_number, system_id: device.system_number, state: bleModel});
    console.log("DataInterface Response ----->", ifResponse);

    //
    // Extraemos los datos para actualizar en el modelo (en formato frontend)
    //
    const dataParsed = cloud2web.parseData(ifResponse.adv_conf, user.units)
    const data = {
      ...dataParsed,
      bleModel,
      backendModel: {...ifResponse.adv_conf, units: device.machine_units},
      id: device.id
    }

    // console.log("data", data);
    await bleToModel.resetDeviceBLEStatus(deviceID);
    await Device.update({data});
    // console.log("Device AFTER UPDATE DEVICE ---->", JSON.stringify(device));

  }

  static getBleSchedulueConf = async deviceID => {
    const bleModel = {};

    const device = Device.find(deviceID);
    // console.log("GET PARAMS: Device in getScheduleConfig =======================> ", device);
    const macBLE = device.macBLE;

    const deviceType = device.device_type;

    let ifDevices;

    if(deviceType === UNIT.DEVICE_TYPE.aidoo || deviceType === UNIT.DEVICE_TYPE.aidoo_it) {
      ifDevices = DataInterface.Device(UNIT.WS_TYPE.ws_aidoo, deviceType);
    } else {
      ifDevices = DataInterface.Device(UNIT.WS_TYPE.ws_az, deviceType);
    }

    // console.log("DATA INTERFACE", ifDevices);

    const user = User.query().first();

    //
    // Obtengo la lista de parámetros de conf de schedule
    //
    const paramList = await device.getParamsBle(macBLE, 'sched');
    //
    // Obtengo la pareja parámetros valor
    //
    let params;
    if(paramList.infoparams &&
    hasProp(paramList.infoparams, 'params') &&
    paramList.infoparams.params.length > 0){
      params = await device.getParamsBleValues(macBLE, paramList.infoparams.params);

      // Si es un sistema de aidoo pro, tenemos que obtener las machine_units de ahí.

      // if(device.device_type === UNIT.DEVICE_TYPE.aidoo_it) {
      //   device.machine_units = params.sys_confmaquina_grados
      //   await device.$save(); // Guardamos el dato en el modelo
      // }
    } else {
      console.log("Error obteniendo lista de parámetros");
      throw new AppError('errorGetDeviceInfo')
    }

    //
    // Creamos el bleModel inicial con los parámetros y agregamos las opts
    //
    Object.assign(bleModel, {
      ...params,
      units: device.machine_units,
    })
    //
    // Obtenemos los datos correspondientes a nuestro modelo web
    //
    const ifResponse = ifDevices.generateBLESchedConf({zone_id: device.zone_number, system_id: device.system_number, state: bleModel});
    // console.log("DataInterface Response: ", ifResponse);
    //
    // Extraemos los datos para actualizar en el modelo (en formato frontend)
    //
    // NOTA: pasamos el tercer parámetro "false" para indicar modos y mode_available con valores numéricos
    const dataParsed = cloud2web.parseData(ifResponse.sched_conf, user.units, false);
    const dataModel = {
      bleModel,
      backendModel: {...ifResponse.adv_conf, units: device.machine_units},
      id: device.id
    }
    const data = {
      ...dataParsed,
      id: device.id,
      units: device.machine_units,
      device_id: device.id,
    }

    // console.log("data", data);
    await bleToModel.resetDeviceBLEStatus(deviceID);
    await Device.update({dataModel});
    await ScheduleConf.insertOrUpdate({data});
  }

  /**
   *  Obtiene icono del dispositivo para vista configuración
   *
   *  @return {String} - devuelve el string del icono
   */
  getIcon = (type = this.type)  => {
      let icon = 'device';
      switch(type) {
        case UNIT.MODEL_TYPE.ZONE:
          icon = 'mode';
          break;
        case UNIT.MODEL_TYPE.CLAMP:
          icon = 'chart';
          break;
        case UNIT.MODEL_TYPE.ACS:
          icon = 'acs';
          break;
        case UNIT.MODEL_TYPE.VMC:
          icon = 'fan';
          break;
        default:
          icon = 'device'
      }

      return icon;
    }

  /**
   *  Obtiene el nombre del dispositivo para vista configuración
   *  devuelve el nombre de zona o un valor por defecto para dispositivos sin nombre
   *
   *  @return {String} - nombre del dispositivo
   */
    get getName() {
      if(this.name) return this.name;

      let name = '';

      const totalOfDeviceType = Device.query().where('type', this.type).get().length;

      if(typeof mapTranslations.DEFAULT_DEVICE_NAME[this.device_type] === 'function') {
        name = mapTranslations.DEFAULT_DEVICE_NAME[this.device_type](this);

        if(totalOfDeviceType > 1) {
          const position = this.zone_number ? this.zone_number:
            this.position ? this.position + 1: '';
          name = `${name} ${position}`;
        }
      }

      return name;
    }

    /**
     * Método para obtener la temperatura según la configuración del usuario.
     *  Sobreescribir en dispositivos con esa funcionalidad
     */

    // eslint-disable-next-line class-methods-use-this
    getTemptInUnits(temp){
      return `Command not supported at this device ${temp}`;
    }

  /**
   * Indica la nueva posición de ordenación de la instalación
   *
   * @param {number} position
   */
  static setPositionDevice = async (installationID, groupID, deviceID, position) => {
      let data = {};

      if(!store.getters.getIsDemo){
        data = await UnitsService.setPositionDevice(installationID, groupID, deviceID, position);

        // Actualizamos el modelo
        await Group.deleteAll();

        console.log("Agregar al modelo",data);
        await Device.insertOrUpdate({ data: data.devices})
        await Group.insertOrUpdate({data: data.groups})
      }

      log.info(`SetDevicePosition ${this.name} to position ${position}`);

    };

  static getSchedules = async (installationID, deviceID) => {
    const response = await UnitsService.getSchedules(installationID, deviceID);

    // const data = {
    //   schedules: response.schedules,
    //   schedule_conf: response.schedulesAvailableConf
    // }

    // await Device.update({
    //   where: deviceID,
    //   data
    // })
    const schedules = response.schedules;
    const schedulesConfData = { id: deviceID, device_id: deviceID, ...response.schedulesAvailableConf}

    //
    // Cargamos la configuración de programación de zona disponible
    //
    await ScheduleConf.insert({data: schedulesConfData});
    // await Schedule.insertOrUpdate({data: schedules});
    //
    // Recorremos las programaciones y las vamos ingresando en el modelo asociándolas al dispositivo
    //
    if( schedules && schedules.length > 0){
      schedules.forEach( async sched => {
        sched.id = sched.schedule_number;
        sched.device_id = deviceID;
        await Schedule.insertOrUpdate({data: sched});
      });
    }

    return response;
  }

  async getParamsBle(macBLE, type = 'advanced'){

    const resp = await BluetoothService.getParams(macBLE, this.system_number, this.zone_number, this.device_type, type);

    return resp;
  }

  async getParamsBleValues(macBLE, params) {
    const deviceData = {
      deviceID: macBLE,
      params,
      systemID: this.system_number,
      zoneID: this.zone_number,
      deviceType: this.device_type
    }

    const resp = await BluetoothService.getParamsBleValues(deviceData);

    return resp;
  }

  async setParamsBle(macBLE, params, deviceType) {
    const resp = await BluetoothService.setParams(macBLE, params, this.system_number, this.zone_number, deviceType);

    return resp.info.params;
  }

  async setBleTest(type, data) {
    return BluetoothService.setTest(this.macBLE, type, data);
  }

  /**
   * @param {string} type - tipo de datos a obtener ['confort', 'aq']
   * @param {string} startDaRte - fecha de comienzo de extracción de datos
   * @param {string} rangeType - tipo ['day', 'month', 'year']
   * @param {string} timeZone - Zona horaria para cálculos de fecha según localización
   * @param {boolean} weather - Devuelve o no datos de la información del clima
   * @param {number} precision - Con qué frecuencia se devuelven muestras en datos muestreados (no dependan de eventos). Default: 5 minutos
   * @param {boolean} double_sp - Si el sistema tiene doble setpoint el modo auto debe implementar una lógica distinta a lo normal
   */
  async getDeviceGraphData(type = 'confort', startDate, rangeType = 'day', timeZone, weather = false, precision = UNIT.GRAPHICS.PRECISION.minutes) {

    const response = await ReportsService.getDeviceGraphData(this.installation_id, this.id, startDate, rangeType, type, timeZone, weather, precision);

    return response;
}

/**
 * @param {string} startDate - fecha de comienzo de extracción de datos
 * @param {string} rangeType - tipo ['day', 'month', 'year']
 * @param {string} timeZone - Zona horaria para cálculos de fecha según localización
 * @param {boolean} weather - Devuelve o no datos de la información del clima
 * @param {number} precision - Con qué frecuencia se devuelven muestras en datos muestreados (no dependan de eventos). Default: 5 minutos
 */
async getMetricsData(startDate, rangeType = 'day') {

  const response = await ReportsService.getMetricsData(this.installation_id, this.id, startDate, rangeType);

  return response;
}

async setDataSharing({ installationId, deviceId, value }) {
  const response = await UnitsService.setDataSharing({ installationId, deviceId, value });

  if(response?.status === 200) this.data_sharing_enabled = value;

  return response;
}

async getMonitoringUrl({ installationId, deviceId }) {
  try {
    const response = await ReportsService.getMonitoringUrl({ installationId, deviceId });

    return response;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

}


export default Device;
