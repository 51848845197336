/* eslint-disable camelcase */
import { Model } from '@vuex-orm/core';
import CONSTANTS from 'Units/constant';
import log from 'Core/services/log.service';
import BluetoothService from 'Core/services/bluetooth.service';
import AppManagerService from 'Core/services/app.manager.service'
import sanitizeWebserver from 'Units/utils/webserver.utils';
import { getAlias } from 'Units/utils/utils';
// import i18n from 'Core/services/language.service';
import BleUtils from 'Units/utils/ble.utils';
import bleToModel from 'Units/interfaces/bleToModel.interface';
import localUserData from 'Auth/services/localUserData.service';
import DemoService from 'Core/services/demo.service';
import Installation from './Installation.model';
import { Device } from './DeviceHierarchy';
import UnitsService from '../services/units.service';
import Group from './Group.model';
import store from 'Core/store/store';

/**
 * @typedef {Object} Webserver
 * @property {string} id - id del webserver
 * @property {string} alias - Alias del webserver
 * @property {string} installation_id - id de la instalación a la que pertenece
 * @property {string} mac - Dirección MAC física del dispositivo
 * @property {string} macBLE - Dirección MAC del Bluetooth. Identifica al dispositivo en las acciones BLE
 * @property {string} pin - Código para emparejar
 * @property {boolean} altherma - Si es un webserver de altherma
 * @property {string} firmware - Firmware
 * @property {string} connection_date - Fecha en que se conectó
 * @property {string} disconnection_date - Fecha en que se desconectó
 * @property {boolean} isConnected - Si el webserver está o no conectado
 * @property {string} type - Tipo de webserver
 * @property {Array<string>} devices - Para la relación uno a muchos de Vuex ORM
 * @property {string} name - Nombre del webserver
 * @property {string} mver - Versión del módulo wifi
 * @property {number} ble_version - Versión de ble implementada en el webserver (qué comandos soporta)
 * @property {string} ip - Dirección ip
 * @property {boolean} isZoneConnectedToUnit - en AIDOO. Webserver conectado a la unidad exterior
 * @property {boolean} isConnectedToNetwork - Webserver conectado al wifi
 * @property {boolean} isConnectedToCloud - Webserver conectado a la cloud
 * @property {boolean} server_sched_active - Programaciones activas
 * @property {string} stat_ap_mac -
 * @property {string} stat_channel -
 * @property {number} stat_quality -
 * @property {number} stat_rssi - Nivel de señal wifi
 * @property {strign} stat_ssid - Nombre de la Wifi
 * @property {number} modbusaddr - Dirección modbus
 * @property {boolean} isDeviceAssociated - El dispositivo ya está asociado
 * @property {boolean} isUserAllow - Permite usuario
 * @property {boolean} isDetecting - Estado detectando sistemas activo. Se usa para saber su estado en las vistas
 * @property {number} units - Unidades (celsius o fah) de la unidad
 * @property {boolean} ws_sched_available - Programaciones semanales permitidas
 * @property {string} ws_hw - Tipo de hardware del webserver
 * @property {string} wsver - Versión del webserver
 * @property {string} lmachine - Versión del lmachine
 * @property {string} aidoo_group - Grupo de Aidoo al que pertenece (WSMM1, WSMM2, WSMM3, WSMM4, WSMM5, WSMM6, WSMM7, WSMM8, WSMM9, WSMM10, LAIDOO, APFANCOIL)
 * @property {Array} test_available - Array de números con el identificador para la constante "BUTTON_TESTS_AVAILABLES" para rellenar los botones de tests en
 * @property {Array} aidooDevices - Array de objetos con el identificador, tipo y configuración de dispositivos aidoo. Ejemplo de un objecto del array: {"device_id": "64527a06697b73bfc81db0d9", "device_type": "aidoo_it", "config": {"integrations": ["google", "alexa"]}}
 */

export default class Webserver extends Model {
  static entity = 'webservers';

  /** *************************************************************
   * CAMPOS
   ************************************************************** */
  static fields() {
    return {
      id: this.attr(null),
      alias: this.string(''),
      altherma: this.boolean(undefined).nullable(), // indica si es altherma
      auxheat_enable: this.number(undefined).nullable(),
      auxheat_ton: this.number(undefined).nullable(),
      auxheat_toff: this.number(undefined).nullable(),
      auxheat_tdelay: this.number(undefined).nullable(),
      auxheat_lockoutenable: this.number(undefined).nullable(),
      auxheat_lockouttemp: this.number(undefined).nullable(),
      auxheat_lowlock_val: this.number(undefined).nullable(),
      auxheat_lowlock_step: this.number(undefined).nullable(),
      auxheat_lowlock_min: this.number(undefined).nullable(),
      auxheat_lowlock_max: this.number(undefined).nullable(),
      auxheat_highlock_val: this.number(undefined).nullable(),
      auxheat_highlock_step: this.number(undefined).nullable(),
      auxheat_highlock_min: this.number(undefined).nullable(),
      auxheat_highlock_max: this.number(undefined).nullable(),
      auxheat_extheatsource: this.number(undefined).nullable(),
      auxheat_emergencyheat: this.number(undefined).nullable(),
      bacnetid: this.number(undefined).nullable(),
      bacnetip: this.number(undefined).nullable(),
      bacnetipid: this.number(undefined).nullable(),
      bacnetmac: this.number(undefined).nullable(),
      bacnetmaxframes: this.number(undefined).nullable(),
      bacnetmaxnum: this.number(undefined).nullable(),
      bacnetmstp: this.number(undefined).nullable(),
      bacneton: this.number(undefined).nullable(),
      bacnetipport: this.number(undefined).nullable(),
      bacnetspeed: this.number(undefined).nullable(),
      ble_version: this.number(undefined).nullable(),
      connection_date: this.string(undefined).nullable(),
      devices: this.hasMany(Device, 'webserver_id'),
      aidooDevices: this.attr(undefined).nullable(),
      test_available: this.attr(undefined).nullable(),
      disconnection_date: this.string(undefined).nullable(),
      dhcp: this.boolean(undefined).nullable(),
      dns: this.string(undefined).nullable(),
      firmware: this.string(undefined).nullable(),
      gateway: this.string(undefined).nullable(),
      gpioalpha: this.number(undefined).nullable(),
      gpiobeta: this.number(undefined).nullable(),
      gpiofanspeed: this.number(undefined).nullable(),
      gpiominruntime: this.number(undefined).nullable(),
      gpiostoptime: this.number(undefined).nullable(),
      gpiounit: this.number(undefined).nullable(),
      hvacsim: this.boolean(undefined).nullable(),
      inet_enabled: this.number(undefined).nullable(),
      installation_id: this.attr(null),
      isConnected: this.boolean(false),
      ip: this.string(undefined).nullable(),
      isZoneConnectedToUnit: this.boolean(undefined).nullable(),
      isConnectedToNetwork: this.boolean(undefined).nullable(),
      isConnectedToCloud: this.boolean(undefined).nullable(),
      isDeviceAssociated: this.boolean(undefined).nullable(),
      isUserAllow: this.boolean(undefined).nullable(),
      isDetecting: this.boolean(false), // Parámetro que controla si está detectando sistema
      isWifi: this.boolean(false),
      isEthernet: this.boolean(false),
      lapi: this.number(undefined).nullable(),
      venstar: this.number(undefined).nullable(),
      ledsoff: this.number(undefined).nullable(),
      lmachine: this.string(undefined).nullable(),
      lutron: this.number(undefined).nullable(),
      control4: this.number(undefined).nullable(),
      slavemodbustcp: this.number(undefined).nullable(),
      slavemodbustcpport: this.number(undefined).nullable(),
      link_check: this.number(undefined).nullable(),
      mac: this.string(undefined).nullable(),
      macBLE: this.string(undefined).nullable(),
      mask: this.string(undefined).nullable(),
      meterConfigVersion: this.number(undefined).nullable(),
      modbusaddr: this.number(undefined).nullable(),
      modbusbitstop: this.number(undefined).nullable(),
      mdbu_maq: this.boolean(undefined).nullable(),
      modbus_type: this.string(undefined).nullable(),
      modbusparity: this.number(undefined).nullable(),
      modbusspeed: this.number(undefined).nullable(),
      mver: this.string(undefined).nullable(),
      name: this.string(undefined).nullable(),
      network_status: this.number(undefined).nullable(),
      ota: this.boolean(undefined).nullable(),
      pin: this.string(undefined).nullable(),
      server_sched_active: this.boolean(undefined).nullable(),
      slavemodbus: this.number(undefined).nullable(),
      success: this.boolean(false).nullable(),
      stat_ap_mac: this.string(undefined).nullable(),
      stat_channel: this.string(undefined).nullable(),
      stat_quality: this.number(undefined).nullable(),
      stat_rssi: this.number(undefined).nullable(),
      stat_ssid: this.string(undefined).nullable(),
      t1t2_enable: this.number(undefined).nullable(),
      t1t2_enable_values: this.attr(undefined).nullable(),
      t1t2_nc: this.number(undefined).nullable(),
      t1t2_ondelay: this.number(undefined).nullable(),
      t1t2_offdelay: this.number(undefined).nullable(),
      type: this.string(undefined).nullable(),
      units: this.number(undefined).nullable(),
      ws_sched_available: this.boolean(undefined).nullable(),
      wizard_compatible: this.boolean(undefined).nullable(),
      wsver: this.string(undefined).nullable(),
      detectSystemDisabled: this.boolean(undefined).nullable(),
      resetDisabled: this.boolean(undefined).nullable(),
      rs485_mode: this.number(undefined).nullable(),
      rs485_availablemodes: this.attr(undefined).nullable(),
      ecobeesb: this.number(undefined).nullable(),
      ecobeesbclientid: this.string(undefined).nullable(),
      ecobeesbclientsecret: this.string(undefined).nullable(),
      ecobeesbthermostatid: this.string(undefined).nullable(),
      ecobeesbstatus: this.number(undefined).nullable(),
      lutronhwqsx: this.number(undefined).nullable(),
      lutronhwqsxstatus: this.number(undefined).nullable(),
      lutronhwqsxmastereveryzone: this.number(undefined).nullable(),
      lutronhwqsxip: this.string(undefined).nullable(),
      mdns_server: this.number(undefined).nullable(),
      ws_hw: this.string(undefined).nullable(),
      wizard_first_sys_detection: this.boolean(undefined).nullable(),
      pelican: this.number(undefined).nullable(),
      pelicansite: this.string(undefined).nullable(),
      pelicanemail: this.string(undefined).nullable(),
      pelicanpass: this.string(undefined).nullable(),
      pelicanserial: this.string(undefined).nullable(),
      pelicanstatus: this.number(undefined).nullable(),
      aidoo_group: this.string(undefined).nullable(),
      region: this.string(undefined).nullable(),
    };
  }

  get isCentral() {
    return this.type === CONSTANTS.DEVICE_TYPE.az_8cb;
  }

  get hasIntegrations() {
    return (
      (this.lapi !== undefined && this.lapi !== null) ||
      (this.venstar !== undefined && this.venstar !== null) ||
      (this.lutron !== undefined && this.lutron !== null) ||
      (this.lutronhwqsx !== undefined && this.lutronhwqsx !== null) ||
      (this.control4 !== undefined && this.control4 !== null) ||
      (this.slavemodbustcp !== undefined && this.slavemodbustcp !== null) ||
      (this.bacnetip !== undefined && this.bacnetip !== null) ||
      (this.modbusaddr !== undefined && this.modbusaddr !== null) ||
      (this.bacnetmstp !== undefined && this.bacnetmstp !== null) ||
      (this.bacneton !== undefined && this.bacneton !== null) ||
      (this.rs485_mode !== undefined && this.rs485_mode !== null) ||
      (this.pelican !== undefined && this.pelican !== null) ||
      (this.ecobeesb !== undefined && this.ecobeesb !== null) )
  }

  get hasDigitalInput() {
    return (
      this.t1t2_enable !== undefined && this.t1t2_enable !== null ||
      this.t1t2_nc !== undefined && this.t1t2_nc !== null ||
      this.t1t2_offdelay !== undefined && this.t1t2_offdelay !== null ||
      this.t1t2_ondelay !== undefined && this.t1t2_ondelay !== null
    );
  }

  get hasAuxheat() {
    return (
      this.auxheat_enable !== undefined && this.auxheat_enable !== null ||
      this.auxheat_ton !== undefined && this.auxheat_ton !== null ||
      this.auxheat_toff !== undefined && this.auxheat_toff !== null ||
      this.auxheat_tdelay !== undefined && this.auxheat_tdelay !== null ||
      this.auxheat_lockoutenable !== undefined && this.auxheat_lockoutenable !== null ||
      this.auxheat_lockouttemp !== undefined && this.auxheat_lockouttemp !== null ||
      this.auxheat_extheatsource !== undefined && this.auxheat_extheatsource !== null ||
      this.auxheat_emergencyheat !== undefined && this.auxheat_emergencyheat !== null ||
      this.auxheat_lowlock_val !== undefined && this.auxheat_lowlock_val !== null ||
      this.auxheat_highlock_val !== undefined && this.auxheat_highlock_val !== null
    )
  }

  get hasFallback() {
    return (
      // this.gpioalpha !== undefined && this.gpioalpha !== null ||
      // this.gpiobeta !== undefined && this.gpiobeta !== null ||
      this.gpiostoptime !== undefined && this.gpiostoptime !== null ||
      this.gpiofanspeed !== undefined && this.gpiofanspeed !== null ||
      this.gpiominruntime !== undefined && this.gpiominruntime !== null
    );
  }

  get hasAidooWSConfig() {
    return (this.hasFallback
      || this.hasDigitalInput
      || this.hasAuxheat)
  }

  get hasAidooConfig() {
    const devices = Device.query().where('webserver_id', this.id).get();

    let hasConfig = false;

    if(this.hasAidooWSConfig) return true;

    devices.forEach( device => {
      if(device.hasAidooConfig){
        hasConfig = true;
      }
    })

    return hasConfig;
  }

  get hasAidooInfo() {
    const devices = Device.query().where('webserver_id', this.id).get();

    let hasInfo = false;

    devices.forEach( device => {
      if(device.hasAidooInfo){
        hasInfo = true;
      }
    })

    return hasInfo;
  }

  /**
   * Devuelve si admite programaciones (como mínimo se refiere a las semanales)
   */
  get hasSchedules(){

    const devices = Device.query().where('webserver_id', this.id).get();
    if(!devices) return false;

    if(this.ble_version < 2) return false;

    if(devices.length >= 1) {
      for(let i=0; i < devices.length; i++) {
        if(devices[i].ws_sched_available && devices[i].ws_sched_available === true ){
            return true;
        }
      }
    }
    return false;
  }

  get hasCalendarSchedules() {
    const devices = Device.query().where('webserver_id', this.id).get();
    if(!devices) return false;

    if(this.ble_version < 2) return false;

    if(devices.length >= 1) {
      for(let i=0; i < devices.length; i++) {
        if(devices[i].ws_sched_available && devices[i].ws_sched_available === true &&
          devices[i].ws_sched_calendar_available && devices[i].ws_sched_calendar_available === true){
            return true;
        }
      }
    }
    return false;
  }

  get hasAcsSchedules() {
    const devices = Device.query().where('webserver_id', this.id).get();
    if(!devices) return false;

    if(this.ble_version < 2) return false;

    if(devices.length >= 1) {
      for(let i=0; i < devices.length; i++) {
        if(devices[i].ws_sched_available && devices[i].ws_sched_available === true &&
          devices[i].acs_sched_available && devices[i].acs_sched_available === true){
            return true;
        }
      }
    }
    return false;
  }

  /**
   * Actualiza un parámetro de una zona (SÓLO EN EL MODELO: Sin enviarlo al servicio)
   *
   * @param {String} param - El parámetro a actualizar
   * @param {String,Number} value - El nuevo valor {param: value}
   */
  setParam = async (param, value) => {
    const wsID = this.id;
    const wsData = {};
    wsData[param] = value;

    await Webserver.update({
      where: wsID,
      data: wsData,
    });
    log.success(`Editado ${param}`);

  };

  setBleExtraInfoParam = async (param, value) => {
    const data = {}

    data[param] = value;

    await BluetoothService.setExtraInfo(this.macBLE, data)

    console.log("DATA", data);

    await Webserver.update({
      where: this.id,
      data,
    });

    log.success(`Editado ${param} a ${value}`);

  }

  setBleParams = async data => {

    await BluetoothService.setWsParams(this.macBLE, data)

    console.log("DATA", data);

    await Webserver.update({
      where: this.id,
      data,
    });

    log.success(`Editado ${JSON.stringify(data)}`);

  }

  setBleParam = async (param, value) => {
    const data = {}

    data[param] = value;

    await BluetoothService.setWsParams(this.macBLE, data)

    console.log("DATA", data);

    await Webserver.update({
      where: this.id,
      data,
    });

    log.success(`Editado ${param} a ${value}`);

  }

  setBleService = async data => {

    await BluetoothService.setWsService(this.macBLE, data)

    console.log("DATA", data);

    await Webserver.update({
      where: this.id,
      data,
    });

    log.success(`Editado ${JSON.stringify(data)}`);

  }

  static validateWebserver = async (macBLE, webserverName, webserverData) => {

    const data = await UnitsService.validateWebserver(macBLE, webserverName, webserverData);

    await Webserver.insertOrUpdate({data});

    return data;

  }

  static linkStatus = async webserverID => {

    const data = await UnitsService.linkStatus(webserverID);

    return data;
  }

  static checkPin = async (webserverID, pin) => {

    await UnitsService.checkPin(webserverID,pin);

    return true;
  }

  setAlias = async (webserverID, alias, installationID, pin) => {

    await UnitsService.setAlias(webserverID, alias, installationID, pin);

    await this.setParam('alias', alias);

    return true;
  }

  static getWebservers = async (installationID, page = 0, items = CONSTANTS.PAGINATION.WEBSERVERS.items_for_page, param = null, value = null, aliasPrefix = null) => {
    const {webservers, totalWebservers} = await UnitsService.getWebservers(installationID, page, items, param, value, aliasPrefix);

    const data = webservers;

    await Installation.update({
      id: installationID,
      totalWebservers
    });

    await Webserver.create({data});

    return true;

  }

  static getWebserversFilters = async (installationID, type, page, items, param = null, value = null, aliasPrefix = null) => {

    const { webservers, totalFilteredWebservers } = await UnitsService.getWebserversFilters(installationID, type, page, items, param, value, aliasPrefix);

    // Usamos el modelo instalación para poder guardar el totalFilteredWebservers el total de webserver filtrados (por ejemplo ws_aidoo)
    await Installation.insertOrUpdate({
      data: {
        id: installationID,
        totalFilteredWebservers
      }
    });

    // NOTA: No usamos el modelo webservers para guardar la primera página.
    // await Webserver.create({data});
    // Devolvemos un objeto con todos los webservers filtrados por tipo y el total, que hay en la instalación
    return { webservers, totalFilteredWebservers };

  }

  static setDeviceSettings = async (installationID, deviceID, mac, selectedDevices, selectAll, excludedDevices) => {

    const response = await UnitsService.setDeviceSettings(installationID, deviceID, mac, selectedDevices, selectAll, excludedDevices);

    // console.log('setDeviceSettings: ', response)
    // Devolvemos respuesta
    return response;

  }

  static getWebserverStatus = async (installationID, webserverID, devices = false) => {
    const response = await UnitsService.getWebserverStatus(installationID, webserverID, devices);

    const data = response.data;
    // data.meterConfigVersion = 1
    const devicesData = response.devicesData;
    if(devices) {
      //
      // Limpiamos los datos previos
      //
      Webserver.delete(webserverID);
      Device.deleteAll();
      //
      // Actualizamos con el nuevo Status
      //
      await Webserver.insertOrUpdate({data});
    } else {
      await Webserver.insertOrUpdate({data});
    }

    if(devicesData && devicesData.length > 0) await Device.create({data: devicesData});
    return response;
  }

  getCloudAlias = async (webserverID, pin) => {
    let alias = null
    const response = await UnitsService.getCloudAlias(webserverID, pin);
    // console.log('getCloudAlias: ', response)
    const data = response.data;
    if (data) {
      alias = data.alias
    }

    return getAlias(alias, webserverID);
  }

  static getWebserverServicesBLE = async (macBLE, webserverID) => {

    if (store.getters.getIsDemo) {
      if (!Webserver.find(webserverID)) DemoService.getBLEDeviceInfo(macBLE);
      return;
    }

    // console.log("=================== GET SERVICES BLE =====================");
    const response = await BluetoothService.getWsServices(macBLE);
    const webserverData = bleToModel.getServicesBle(response);

    // Pedimos y asignamos parámetros Pelican si tiene ese servicio y está activado
    if (webserverData && webserverData.pelican === 1) {
      const pelicanData = await BleUtils.getAidooPelicanStatus(macBLE, webserverID);
      webserverData.pelicansite = pelicanData.webserverData.pelicansite
      webserverData.pelicanemail = pelicanData.webserverData.pelicanemail
      webserverData.pelicanpass = pelicanData.webserverData.pelicanpass
      webserverData.pelicanserial = pelicanData.webserverData.pelicanserial
      webserverData.pelicanstatus = pelicanData.webserverData.pelicanstatus
    }

    webserverData.id = webserverID;
    // console.log("WEBSERVER DATA ------>", webserverData);
    await Webserver.insertOrUpdate({data: webserverData});
  }

  static getEcobeeSBStatusBLE = async (macBLE, webserverID) => {
    console.log("GET_ECOBEESB_STATUS_BLE", macBLE, webserverID);

    const data = await BleUtils.getAidooEcobeeSBStatus(macBLE, webserverID);
    if(data && data.webserverData !== undefined) {
      await Webserver.insertOrUpdate({
        data: data.webserverData
      })

    }
    const ecobeeSBStatus = data.webserverData && data.webserverData.ecobeesbstatus !== null && data.webserverData.ecobeesbstatus !== undefined ? data.webserverData.ecobeesbstatus : 0

    return ecobeeSBStatus
  }

  static getPelicanStatusBLE = async (macBLE, webserverID) => {
    console.log("GET_PELICAN_STATUS_BLE", macBLE, webserverID);

    const data = await BleUtils.getAidooPelicanStatus(macBLE, webserverID);
    if(data && data.webserverData !== undefined) {
      await Webserver.insertOrUpdate({
        data: data.webserverData
      })

    }
    const pelicanStatus = data.webserverData && data.webserverData.pelicanstatus !== null && data.webserverData.pelicanstatus !== undefined ? data.webserverData.pelicanstatus : 0

    return pelicanStatus
  }

  static getWebserverStatusBLE = async (macBLE, webserverID, type, bleVersion, wsVersion, userEmail) => {
    console.log("GET_WS_STATUS_BLE", macBLE, webserverID, type);
    let data;
    if(type === CONSTANTS.DEVICE_TYPE.aidoo && (bleVersion >= 1 || parseInt(wsVersion.split('.')[0], 10) >= 6)) {

      data = await BleUtils.getAidooWsStatus(macBLE, webserverID, bleVersion);

      // Pedimos y asignamos parámetros ecobee SB si tiene ese servicio y está activado
      if (data && data.webserverData && data.webserverData.ecobeesb === 1) {
        const ecobeeData = await BleUtils.getAidooEcobeeSBStatus(macBLE, webserverID);
        data.webserverData.ecobeesbclientid = ecobeeData.webserverData.ecobeesbclientid
        data.webserverData.ecobeesbclientsecret = ecobeeData.webserverData.ecobeesbclientsecret
        data.webserverData.ecobeesbthermostatid = ecobeeData.webserverData.ecobeesbthermostatid
        data.webserverData.ecobeesbstatus = ecobeeData.webserverData.ecobeesbstatus
      }

      // await Webserver.insertOrUpdate({data: webserverData});
    } else if(type === CONSTANTS.DEVICE_TYPE.az_ws ||
      type === CONSTANTS.DEVICE_TYPE.az_airqsensor ||
      type === CONSTANTS.DEVICE_TYPE.az_8cb){

      data = await BleUtils.getWsStatus(macBLE, webserverID, type, userEmail);
    }

    if(data?.webserverData !== undefined) {
      await Webserver.insertOrUpdate({
        data: data.webserverData
      })
    }

    if(data?.devicesData !== undefined && data.devicesData.length > 0){
      await Device.insertOrUpdate({data: data.devicesData});
    }

    if(bleVersion >= 2) {
      await this.getScheduleConfBLE(macBLE, webserverID);
    }

    return true
  }

  getLocalUserAllow = async (userEmail, webserverID) => {
    let isUserAllow = false;
    if(userEmail !== undefined){
      // if(type === constants.DEVICE_TYPE.az_8cb && userEmail !== undefined){
      localUserData.init();
      const pincode = localUserData.getDevicePincode(userEmail, webserverID)

      if(pincode){
        try {

          await UnitsService.checkPin(webserverID, pincode);

          // Tras comprobar que el usuario y su pin guardado son válidos, lo actualizo en el modelo
          await this.setParam('pin', pincode);

          isUserAllow = true;
        } catch(err) {
          console.log("Usuario no permitido",err)
          isUserAllow = false;
        }
      }
    }

    return isUserAllow;
  }

  /**
   * Obtiene el estado del webserver/central y su topología. Además actualiza el modelo de datos
   * de todos los dispositivos conectados.
   *
   * @param {String} macBLE - id BLE
   * @param {String} webserverID - device id
   * @param {String} type - type of webserver [aidoo, airzone, central, etc...]
   * @param {Number} bleVersion - api ble version
   * @param {String} wsVersion - webserver version
   * @param {String} userEmail - user email
   */
  static getTopologyStatus = async (macBLE, webserverID, type, bleVersion, wsVersion, userEmail) => {
    try {
      const ws = Webserver.find(webserverID);
      //
      // Obtenemos la info del webserver
      //
      await Webserver.getWebserverStatusBLE(macBLE, webserverID, type, bleVersion, wsVersion, userEmail);

      if(BleUtils.compatibleIntegrations(ws)) {
        await Webserver.getWebserverServicesBLE(macBLE, webserverID);
      }
      //
      // Obtenemos la info de Sistemas
      //
      const devices = Device.all();

      if(devices && devices.length > 0){
        // Esperamos a obtener la configuración de todos los dispositivos
        //------
        // NOTA: No podemos hacerlo en paralelo aunque sea más óptimo debido al flujo real de la comunicación BLE
        //-----
        // await Promise.all(devices.map(async dev => {
        //   await Device.getBleConfig(dev.id)
        // }));
        // Por ello lo hacemos la forma menos deseada (await dentro de bucle for)
        // eslint-disable-next-line no-restricted-syntax
        for (const dev of devices) {
          // eslint-disable-next-line no-await-in-loop
          await Device.getBleConfig(dev.id);
        }
      }

    } catch( error) {
      throw new Error(error);
    }

  }

  /**
   *
   * @param {string} macBLE mac ble
   * @param {string} webserverID webserver ID
   * @param {object} meta Objeto con información adicional para la topología: Ejemplo: {view: "info_extended"} <- Obtiene get topology extended
   * @param {boolean} clearDevices Si es true limpia todo el modelo de devices antes de crear el nuevo modelo
   */
  static getTopology = async (macBLE, webserverID, clearDevices = false, meta) => {
    const resp = await BluetoothService.getTopologyInfo(macBLE, webserverID, meta);

    console.log("GET_TOPOLOGY", resp);
    const detectSystemDisabled = resp.detectSystemDisabled;
    const devicesData = resp.devicesData;

    await Webserver.insertOrUpdate({
      data: {
        id: webserverID,
        detectSystemDisabled
      }
    })

    if(clearDevices) await Device.deleteAll();

    if(devicesData && devicesData.length > 0) await Device.insertOrUpdate({data: devicesData});
  }

  static getMetersConf = async (macBLE, webserverID) => {
    const data = await BleUtils.getMetersConf(macBLE, webserverID);
    console.log('getMetersConf: ', data?.meters_conf)
    return data?.meters_conf
  }

  static setTest = async (macBLE, type, data) => {
    const resp = await BleUtils.setTest(macBLE, type, data);
    console.log('setTest: ', resp)
    return resp
  }

  static getScheduleConfBLE = async (macBLE, webserverID) => {
    const devices = Device.all();

    const response = await bleToModel.getScheduleConfBLE(macBLE, webserverID, devices);

    if(response === null) return;

    const groupData = response.groupData;
    const installationData = response.installationData;
    //
    // Actualizamos los datos parseados desde BLE en nuestro modelo
    //
    if(groupData && groupData.length > 0) await Group.insertOrUpdate({data: groupData});

    Installation.create({data: installationData});


  }

  static saveExtraInfoWebserverBLE = async (macBLE, data) => {
    const webserverData = sanitizeWebserver(macBLE, data);
    // console.log("WEBSERVER DATA ------>", webserverData);
    await Webserver.insertOrUpdate({data: webserverData})
    return webserverData;
  }

  static deleteWebserver = async (installationID, webserverID) => {
    const response = await UnitsService.deleteWebserver(installationID,webserverID);

    console.log(`Webserver eliminado: ${response}`);

    Webserver.delete(webserverID);
  }

  static freeWebserver = async (webserverID, pin = null) => {
    await UnitsService.freeWebserver(webserverID, pin);
    const data = {
      isDeviceAssociated: false
    }

    await Webserver.update({
      where: webserverID,
      data
    });

    console.log(`Webserver liberado: ${webserverID}`);


  }

  /**
   *
   * @param {boolean} isBle Si es true estamos detectando sistemas del webserver por BLE
   * @returns
   */
  detectSystems = async (isBle = false) => {

    if(!isBle) {
      await UnitsService.detectSystems(this.id, this.installation_id);
    } else {
      console.log("Detecando sistemas desde BLE")
      const data = await BluetoothService.detectSystems(this.macBLE, this.id, this.ble_version);
      console.log("Datos para el modelo", data);
      if(data && data.length > 0) {
        await Device.deleteAll();
        log.success('borrando datos del modelo');

        await Device.insertOrUpdate({data});
        log.success('actualizando datos del modelo');

      }
      await this.setParam('isDetecting', false);
    }

    return true;
  }

  static updateNetworkConfig = async (macBLE, network, callback)=> {

    const response = await BluetoothService.updateNetworkConfig(macBLE, network, callback);

    return response;
  }

  static resetBle = async macBLE => {
    const response = await BluetoothService.resetWebserver(macBLE);

    return response;
  }

  static createMetric = (type, cmd, params, webserverID) => {

    if (!webserverID) {
      // Al ser ble se consulta el id del primer webserver
      const ws = Webserver.query().first()
      webserverID = ws.id
    }

    const data = AppManagerService.createMetric(webserverID, type, cmd, params);

    return data;
  }

  static getDeviceVersion = async (deviceID, installationId) => {
    const data = await UnitsService.getDeviceVersion(deviceID, installationId);

    return data;
  }

  static upgradeDeviceVersion= async (deviceId, installationId) => {
    const data = await UnitsService.upgradeDeviceVersion(deviceId, installationId);

    return data;
  }

  static setUniversalModbusMap = async (brandId, machineVersion, modelId, modbusMapCode) => {
    const response = await UnitsService.setUniversalModbusMap(brandId, machineVersion, modelId, modbusMapCode)
    return response
  }
}
