import i18n from 'Core/services/language.service';
import {capitalize} from 'Core/utils/utils';
import constants from 'Units/constant';

/**
 *  MAPA DE TRADUCCIONES
 *  Parámetros de configuración
 */

const mapTranslations = {

  AQ_QUALITY: {
    good: () => i18n.global.t('purifier.good'),
    bad: () => i18n.global.t('purifier.bad'),
    regular: () => i18n.global.t('purifier.regular'),
  },

  AQ_MODE_VALUES:  {
    off: () => i18n.global.t('off'),
    on: () => i18n.global.t('on'),
    auto: () => i18n.global.t('unit.auto')
  },

  AQ_VENT_DEMAND_TYPE: {
    forced: () => i18n.global.t('accesibility.ventilating'),
    auto: () => i18n.global.t('accesibility.ventilating'),
    renew_air: () => i18n.global.t('accesibility.cyclic_vent'),
    free_cooling: () => i18n.global.t('accesibility.free_cooling'),
  },

  AUTOCHANGE_TEMP_VALUES: {
    local: () => i18n.global.t('unit.th'),
    exterior: () => i18n.global.t('unit.ext_temp'),
  },

  AQ_VENT_MODE_VALUES:  {
    off: () => i18n.global.t('off'),
    on: () => i18n.global.t('on'),
    auto: () => i18n.global.t('unit.auto')
  },

  AQ_VENT_MODE_VALUES:  {
    off: () => i18n.global.t('off'),
    on: () => i18n.global.t('on'),
    auto: () => i18n.global.t('unit.auto')
  },

  AUTOCHANGE_TEMP_VALUES: {
    local: () => i18n.global.t('unit.th'),
    exterior: () => i18n.global.t('unit.ext_temp'),
  },

  AUTOCHANGE_FORCED_VALUES: {
    deactivated: () => i18n.global.t('system_config.disabled-copy'),
    heat_open_cool_closed: () => i18n.global.t('system_config.mode.heat_open'),
    cool_open_heat_closed: () => i18n.global.t('system_config.mode.cool_open')
  },

  AUX_HEAT_MAIN_STAGE: {
    aux: () => i18n.global.t('system_config.heat_aux'),
    pump: () => i18n.global.t('system_config.heat_pump'),
  },

  AUX_HEAT_VENT_CONF: {
    electric: () => i18n.global.t('system_config.electric'),
    furnace: () => i18n.global.t('system_config.furnace'),
  },

  AZ_OUTPUTS_TYPES_MAP: {
    dx: () => i18n.global.t('system_config.outputs_types_options.direct'),
    fancoil: () => i18n.global.t('system_config.outputs_types_options.fancoil'),
    floor: () => i18n.global.t('system_config.outputs_types_options.underfloor_heating_water'),
    ceiling_rad: stage => stage === constants.STAGES_MODE.heat ? i18n.global.t('system_config.outputs_types_options.heating_ceiling_rad') : i18n.global.t('system_config.outputs_types_options.cooling_ceiling'),
    electric: () => i18n.global.t('system_config.outputs_types_options.heating_electric'),
    trv: () => i18n.global.t('system_config.outputs_types_options.heating_water'),
  },

  // AUX_HEAT_TON_OPTIONS: {
  //   '-40': () => '-7.2 ºF (-4.0 ºC)',
  //   '-35': () => '-6.3 ºF (-3.5 ºC)',
  //   '-30': () => '-5.4 ºF (-3.0 ºC)',
  //   '-25': () => '-4.5 ºF (-2.5 ºC)',
  //   '-20': () => '-3.6 ºF (-2.0 ºC)',
  //   '-15': () => '-2.7 ºF (-1.5 ºC)',
  // },

  // AUX_HEAT_TOFF_OPTIONS: {
  //   '-20': () => '-3.6 ºF (-2.0 ºC)',
  //   '-15': () => '-2.7 ºF (-1.5 ºC)',
  //   '-10': () => '-1.8 ºF (-1.0 ºC)',
  //   '-5': () => '-0.9 ºF (-0.5 ºC)',
  //   '0': () => '0 ºF (0 ºC)',
  //   '10': () => '0.9 ºF (0.5 ºC)',
  // },

  AUX_HEAT_EXT_SOURCE_OPTIONS: {
    0: () => i18n.global.t('addDevice.auxheat.duct'),
    1: () => i18n.global.t('addDevice.auxheat.radiant'),
  },

  AZ_INSTALLATION_TYPES: {
    zone: () => i18n.global.t('system_config.system_type_options.zone'),
    zone_fc:() => i18n.global.t('system_config.system_type_options.zone_fc'),
    two_tubes:() => i18n.global.t('system_config.system_type_options.two_tubes'),
    four_tubes:() => i18n.global.t('system_config.system_type_options.four_tubes'),
    c6:() => i18n.global.t('system_config.system_type_options.c6'),
    c8:() => i18n.global.t('system_config.system_type_options.c8'),
    radiante:() => i18n.global.t('system_config.system_type_options.radiante'),
    radiante_v:() => i18n.global.t('system_config.system_type_options.radiante_v'),
    vaf:() => i18n.global.t('system_config.system_type_options.vaf'),
    two_tubes_two_wires: () => i18n.global.t('system_config.system_type_options.two_tubes_two_wires'),
  },

  AZ_DEVICES_STATES: {
    error: () => '',
    perparing: () => '',
    loading: () => i18n.global.t('messages.loading'),
    stop: () => '',
    off: () => i18n.global.t('unit.off'),
    success: () => i18n.global.t('unit.success'),
    drying: () => i18n.global.t('unit.drying'),
    airing: () => i18n.global.t('accesibility.ventilating'),
    cooling: () => i18n.global.t('unit.cooling'),
    heating: () => i18n.global.t('unit.heating'),
  },

  DEFAULT_DEVICE_NAME: {
    [constants.DEVICE_TYPE.az_system]: device => device?.name || i18n.global.t('system'),
    [constants.DEVICE_TYPE.az_zone]: device => device?.name || i18n.global.t('installations.zone'),
    [constants.DEVICE_TYPE.aidoo]: device => device?.name || i18n.global.t(device?.two_zones_water? 'installations.water_circuit' : 'installations.zone'),
    [constants.DEVICE_TYPE.aidoo_it]: device => device?.name || i18n.global.t(device?.two_zones_water? 'installations.water_circuit' : 'installations.zone'),
    [constants.DEVICE_TYPE.az_acs]: device => device?.name || i18n.global.t('unit.acs'),
    [constants.DEVICE_TYPE.aidoo_acs]: device => device?.name || i18n.global.t('unit.acs'),
    [constants.DEVICE_TYPE.az_vmc]: device => device?.name || i18n.global.t('system_config.vmc'),
    [constants.DEVICE_TYPE.aidoo_vmc]: device => device?.name || i18n.global.t('system_config.vmc'),
    [constants.DEVICE_TYPE.az_relay]: device => device?.name || i18n.global.t('system_config.control'),
    [constants.DEVICE_TYPE.az_airqsensor]: device => device?.name || i18n.global.t('airtools.sensor'),
  },

  DIGITAL_INPUT_T1T2_ENABLE: {
    0: () => i18n.global.t('addDevice.digitalInput.disabled'),
    1: () => i18n.global.t('addDevice.digitalInput.enabledStatus'),
    2: () => i18n.global.t('addDevice.digitalInput.enabledFlank'),
    3: () => i18n.global.t('system_config.r32_alarm'),
  },

  DIGITAL_INPUT_T1T2_ENABLE_TITLE: {
    0: () => i18n.global.t('addDevice.digitalInput.disabled'),
    1: () => i18n.global.t('addDevice.digitalInput.enabledStatus'),
    2: () => i18n.global.t('addDevice.digitalInput.enabledFlank'),
    3: () => i18n.global.t('system_config.r32_alarm'),
  },

  DIGITAL_INPUT_T1T2_ENABLE_DESC: {
    0: () => i18n.global.t('addDevice.digitalInput.disabledDesc'),
    1: () => i18n.global.t('addDevice.digitalInput.enabledStatusDesc'),
    2: () => i18n.global.t('addDevice.digitalInput.enabledFlankDesc'),
    3: () => i18n.global.t('system_config.r32_info'),
  },

  DIGITAL_INPUT_T1T2_NC: {
    0: () => i18n.global.t('addDevice.digitalInput.nOpened'),
    1: () => i18n.global.t('addDevice.digitalInput.nClosed'),
  },

  ECO_ADAPT_VALUES: {
    manual: () => i18n.global.t('systems.eco.eco-m'),
    off: () => i18n.global.t('systems.eco.eco-off'),
    a: () => i18n.global.t('systems.eco.eco-a'),
    a_p: () => i18n.global.t('systems.eco.eco-aa'),
    a_pp: () => i18n.global.t('systems.eco.eco-aaa'),
  },

  ECO_SENSOR_VALUES: {
    deactivate: () => i18n.global.t('zones.adjusts.adjust_zone.off'),
    open: () => i18n.global.t('zones.adjusts.adjust_zone.function_eco.1'),
    closed: () => i18n.global.t('zones.adjusts.adjust_zone.function_eco.2')
  },

  FALLBACK_ALGORITHM:{
    3: () => '3 ºF - 1.66 ºC',
    4: () => '5 ºF - 2.78 ºC',
    7: () => '7 ºF - 3.89 ºC'
  },

  GPIO_FAN_SPEEDS: {
    1: () => `${i18n.global.t('addDevice.fallback.speed')} 1`,
    2: () => `${i18n.global.t('addDevice.fallback.speed')} 2`,
    3: () => `${i18n.global.t('addDevice.fallback.speed')} 3`,
    4: () => `${i18n.global.t('addDevice.fallback.speed')} 4`,
    5: () => `${i18n.global.t('addDevice.fallback.speed')} 5`,
    6: () => `${i18n.global.t('addDevice.fallback.speed')} Auto`,
  },

  INSTALLATION_TYPE_CCP: {
    aerothermal: () => i18n.global.t('system_config.ccp_options.aerothermal'),
    pipes2: () => i18n.global.t('system_config.ccp_options.pipes2'),
    pipes4: () => i18n.global.t('system_config.ccp_options.pipes4'),
    radianT: () => i18n.global.t('system_config.ccp_options.radianT'),
  },

  MAIN_STAGE_VALUES: {
    radiant: () => i18n.global.t('system_config.demand_radiant'),
    air: () => i18n.global.t('system_config.air'),
  },

  MAIN_STAGE_MODES: {
    HEAT: {
      radiant: () => i18n.global.t('systems.modes.heat-radiant'),
      air: () => i18n.global.t('systems.modes.heat-air'),
    },
    COOL: {
      radiant: () => i18n.global.t('systems.modes.cool-radiant'),
      air: () => i18n.global.t('systems.modes.cool-air'),
    }
  },

  MIXING_VALVE_VALUES_CCP: {
    auto: () => i18n.global.t('system_config.auto'),
    man: () => i18n.global.t('system_config.man'),
  },


  MLI_MANUFACTURERS: {
    unknw: () => i18n.global.t('zones.adjusts.adjust_zone.manufacturer_0'),
    fujGen1: () => i18n.global.t('zones.adjusts.adjust_zone.manufacturer_1'),
    fujGen2: () => i18n.global.t('zones.adjusts.adjust_zone.manufacturer_2'),
    daikin1: () => i18n.global.t('zones.adjusts.adjust_zone.manufacturer_3'),
    daikin2: () => i18n.global.t('zones.adjusts.adjust_zone.manufacturer_4'),
    lg: () => i18n.global.t('zones.adjusts.adjust_zone.manufacturer_5'),
    tango: () => i18n.global.t('zones.adjusts.adjust_zone.manufacturer_6'),
    mitsuelec: () => i18n.global.t('zones.adjusts.adjust_zone.manufacturer_7'),
    toshiba: () => i18n.global.t('zones.adjusts.adjust_zone.manufacturer_8'),
    gree: () => i18n.global.t('zones.adjusts.adjust_zone.manufacturer_9'),
  },

  PRESENCE_WINDOW_NAMES: {
    deactivate: () => i18n.global.t('zones.adjusts.adjust_zone.off'),
    open: () => i18n.global.t('zones.adjusts.adjust_zone.presence_normally_open'),
    closed: () => i18n.global.t('zones.adjusts.adjust_zone.presence_normally_closed')
  },

  FALLBACK_NAMES: {
    app: () => i18n.global.t('zones.adjusts.adjust_zone.app'),
    thermostat: () => i18n.global.t('zones.adjusts.adjust_zone.thermostat')
  },

  DUALSP_AUTO_NAMES: {
    0: () => i18n.global.t('zones.adjusts.adjust_zone.not_dualsp_auto_conf'),
    1: () => i18n.global.t('zones.adjusts.adjust_zone.dualsp_auto_conf'),
  },

  PARITIES: {
    0: () => i18n.global.t('airtools.parity_none'),
    1: () => i18n.global.t('airtools.parity_odd'),
    2: () => i18n.global.t('airtools.parity_even'),
  },

  PRICE_RATES_PERIOD: {
    1: () => i18n.global.t('price.rates.high.title'),
    2: () => i18n.global.t('price.rates.mid.title'),
    3: () => i18n.global.t('price.rates.low.title'),
  },

  PRICE_RATES_LOCATIONS: {
    peninsula: () => i18n.global.t('price.places.peninsula'),
    baleares: () => i18n.global.t('price.places.baleares'),
    canarias: () => i18n.global.t('price.places.canarias'),
    ceuta: () => i18n.global.t('price.places.ceuta'),
    melilla: () => i18n.global.t('price.places.melilla')
  },

  PROTECTION_DEW_NAMES: {
    very_low: () => i18n.global.t('system_config.rocio_hist_very_low'),
    low: () => i18n.global.t('system_config.rocio_hist_low'),
    medium: () => i18n.global.t('system_config.rocio_hist_medium'),
    high: () => i18n.global.t('system_config.rocio_hist_high'),
    very_high: () => i18n.global.t('system_config.rocio_hist_very_high'),
  },

  UNVERSAL_MODBUS_TYPE: {
    ac: () => i18n.global.t('addDevice.ac'),
    fan: () => i18n.global.t('addDevice.fanTo'),
    aerothermal: () => i18n.global.t('system_config.ccp_options.aerothermal'),
  },

  PROTOCOL_TYPE_AIDOO: {
    0: () => i18n.global.t('addDevice.protocol.modbusAidoo'),
    1: () => i18n.global.t('addDevice.protocol.bacnetAidoo')
  },

  PROTOCOL_TYPE_AIRZONE: {
    0: () => i18n.global.t('addDevice.protocol.modbusAirzone'),
    1: () => i18n.global.t('addDevice.protocol.bacnetAirzone')
  },

  QADAPT_NAMES: {
    standard: () => capitalize(i18n.global.t('system_config.standard')),
    silence: () => capitalize(i18n.global.t('system_config.silence')),
    min: () => capitalize(i18n.global.t('system_config.min')),
    max: () => capitalize(i18n.global.t('system_config.max')),
    power: () => capitalize(i18n.global.t('system_config.power')),
  },

  RELAY_CONFIG: {
    radiant: () => i18n.global.t('system_config.demand_radiant'),
    vmc: () => i18n.global.t('system_config.demand_vmc'),
  },

  O1O2_CONFIG: {
    high: () => i18n.global.t('system_config.high'),
    low: () => i18n.global.t('system_config.low'),
    acs: () => i18n.global.t('system_config.acs'),
    vmc: () => i18n.global.t('system_config.vmc'),
    relay: () => i18n.global.t('system_config.relay'),
    air_demand: () => i18n.global.t('system_config.air_demand'),
  },

  DI1_CONFIG: {
    deactivated: () => i18n.global.t('system_config.disabled'),
    bridge_alarm: () => i18n.global.t('system_config.alarm_nc'),
    acoustic_alarm_nc: () => i18n.global.t('system_config.acoustic_alarm_nc'),
    acoustic_alarm_na: () => i18n.global.t('system_config.acoustic_alarm_na'),
  },

  WORK_SENSOR_CONFIG: {
    easyzone: () => i18n.global.t('airtools.easyzone'),
    airqbox: () => i18n.global.t('airtools.airq_box'),
    airqsensor: () => i18n.global.t('airtools.airq_sensor'),
  },

  RELAY_FW: {
    1: () => i18n.global.t('system_config.rele_fw_1'),
    2: () => i18n.global.t('system_config.rele_fw_2'),
    3: () => i18n.global.t('system_config.rele_fw_3'),
    4: () => i18n.global.t('system_config.rele_fw_4'),
    5: () => i18n.global.t('system_config.rele_fw_5'),
    6: () => i18n.global.t('system_config.rele_fw_6'),
    7: () => i18n.global.t('system_config.rele_fw_7'),
    8: () => i18n.global.t('system_config.rele_fw_8'),
  },

  RS485_AVAILABLEMODES: {
    0: () => i18n.global.t('addDevice.protocol.modbusAidoo'),
    1: () => i18n.global.t('addDevice.protocol.bacnetAidoo'),
    2: () => i18n.global.t('addDevice.protocol.airQ'),
    3: () => i18n.global.t('addDevice.protocol.paladiom'),
    4: () => i18n.global.t('addDevice.protocol.meters'),
    5: () => i18n.global.t('addDevice.protocol.airzoneThermostat'),
    6: () => i18n.global.t('addDevice.protocol.aidoo_pro_aerothermal'),
    7: () => i18n.global.t('addDevice.protocol.modbusAirzone'),
    8: () => i18n.global.t('addDevice.protocol.bacnetAirzone'),
    9: () => i18n.global.t('addDevice.protocol.airzoneGateway'),
    10: () => i18n.global.t('addDevice.protocol.modbusAirzone'),
  },

  SCENE_NAMES: {
    stop: () => i18n.global.t('systems.scene.stop'),
    comfort: () => i18n.global.t('systems.scene.confort'),
    unoccupied: () => i18n.global.t('systems.scene.unocupied'),
    night: () => i18n.global.t('systems.scene.night'),
    eco: () => i18n.global.t('systems.scene.eco'),
    vacation: () => i18n.global.t('systems.scene.vacation'),
  },

  THERMOSTAT_TYPES: {
    blueface: () => i18n.global.t('unit.thermostat_type.blueface'),
    literadio: () => i18n.global.t('unit.thermostat_type.literadio'),
    litecable: () => i18n.global.t('unit.thermostat_type.litecable'),
    lite: () => i18n.global.t('unit.thermostat_type.lite'),
    thinkradio: () => i18n.global.t('unit.thermostat_type.thinkradio'),
    thinkcable: () => i18n.global.t('unit.thermostat_type.thinkcable'),
    virtual: () => i18n.global.t('installations.zone'),
    knx: () => i18n.global.t('installations.zone'),
    bluefacezero: () => i18n.global.t('unit.thermostat_type.bluefacezero')
  },

  USERMODE_SCHED: {
    stop: () => i18n.global.t('systems.scene.stop'),
    comfort: () => i18n.global.t('systems.scene.confort'),
    unoccupied: () => i18n.global.t('systems.scene.unocupied'),
    night: () => i18n.global.t('systems.scene.night'),
    eco: () => i18n.global.t('systems.scene.eco'),
    vacation: () => i18n.global.t('systems.scene.vacation'),
    vla_on: () => '',
    vla_off: () => ''
  },

  MODES: {
    heat: () => i18n.global.t('systems.modes.heat'),
    cool: () => i18n.global.t('systems.modes.cool'),
    fan: () => i18n.global.t('systems.modes.ventilate'),
    stop: () => i18n.global.t('systems.modes.stop'),
    emergencyHeat: () => i18n.global.t('systems.modes.emergency-heat'),
    auto: () => i18n.global.t('systems.modes.auto'),
    dry: () => i18n.global.t('systems.modes.dehumidifier'),
    heat_air: () => i18n.global.t('systems.modes.heat-air'),
    heat_radiant: () => i18n.global.t('systems.modes.heat-radiant'),
    heat_combined: () => i18n.global.t('systems.modes.heat-both'),
    cool_air: () => i18n.global.t('systems.modes.cool-air'),
    cool_radiant: () => i18n.global.t('systems.modes.cool-radiant'),
    cool_combined: () => i18n.global.t('systems.modes.cool-both'),
    bypass: () => i18n.global.t('systems.modes.bypass'),
    recovery: () => i18n.global.t('systems.modes.recovery'),
  },

  SLATS_OPTIONS: {
    auto: () => i18n.global.t('unit.slats.auto'),
    fixed: () => i18n.global.t('unit.slats.fixed'),
    swing: () => i18n.global.t('unit.slats.swing'),
    swings: () => i18n.global.t('unit.slats.swings'),
    pos0: () => i18n.global.t('unit.slats.pos0'),
    pos1: () => i18n.global.t('unit.slats.pos1'),
    pos2: () => i18n.global.t('unit.slats.pos2'),
    pos3: () => i18n.global.t('unit.slats.pos3'),
    pos4: () => i18n.global.t('unit.slats.pos4'),
    pos5: () => i18n.global.t('unit.slats.pos5'),
    pos6: () => i18n.global.t('unit.slats.pos6'),
    pos7: () => i18n.global.t('unit.slats.pos7'),
    pos8: () => i18n.global.t('unit.slats.pos8'),
    pos9: () => i18n.global.t('unit.slats.pos9'),
  },

  STAGES_NAME: {
    off: () => i18n.global.t('zones.adjusts.adjust_zone.off'),
    air: () => i18n.global.t('zones.adjusts.adjust_zone.air'),
    radiant: () => i18n.global.t('zones.adjusts.adjust_zone.radiant'),
    combined: () =>  i18n.global.t('zones.adjusts.adjust_zone.combined'),
  },

  STAGES_CONTROL_VALUES: {
    off: () => i18n.global.t('zones.adjusts.adjust_zone.new_control_stage.off'),
    on: () => i18n.global.t('zones.adjusts.adjust_zone.new_control_stage.on'),
    dx: () => i18n.global.t('zones.adjusts.adjust_zone.new_control_stage.dx'),
    water: () => i18n.global.t('zones.adjusts.adjust_zone.new_control_stage.water'),
    supply: () => i18n.global.t('zones.adjusts.adjust_zone.new_control_stage.supply'),
    floor: () => i18n.global.t('zones.adjusts.adjust_zone.new_control_stage.floor'),
    ceiling: () => i18n.global.t('zones.adjusts.adjust_zone.new_control_stage.ceiling'),
    ceiling_rad: () => i18n.global.t('zones.adjusts.adjust_zone.new_control_stage.ceiling_rad'),
    electric: () => i18n.global.t('zones.adjusts.adjust_zone.new_control_stage.electric'),
  },

  VAF_SUPPLY_TYPE_VALUES: {
    electric: () => i18n.global.t('zones.adjusts.adjust_zone.new_control_stage.water'),
    water: () => i18n.global.t('system_config.electric')
  },

  VMC_RELAY_MODE_VALUES: {
    auto: () => i18n.global.t('unit.auto'),
    manual_on: () => i18n.global.t('schedules.vmc_relay.manual_on'),
    manual_off: () => i18n.global.t('schedules.vmc_relay.manual_off')
  },

  WORK_TEMP_VALUES: {
    thermostat: () => i18n.global.t('unit.thermostat'),
    return: () => i18n.global.t('unit.return_probe'),
    airq_sensor: () => i18n.global.t('airtools.airq_sensor'),
    third_p: () => i18n.global.t('unit.third_p')
  },

  R32_READY: {
    ac_not_avail: () => i18n.global.t('accesibility.not_available'),
    ac_input_protocol: () => i18n.global.t('systems.protocol_alarm'),
    ac_input_alarm: () => i18n.global.t('systems.audible_alarm_input'),
  },

  NETWORK_SECURITY_TYPE: {
    WPA: () => i18n.global.t('addDevice.wifi.securityState.wpa'),
    WEP: () => i18n.global.t('addDevice.wifi.securityState.wep'),
    NONE: () => i18n.global.t('addDevice.wifi.securityState.none'),
  }
}


export default mapTranslations;
