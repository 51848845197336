import StorageService from 'Core/services/storage.service';
import moment from 'moment'

const ReviewService = {
  // Lanza el modal de valoración
  launchRating() {
    LaunchReview.rating(function(status){ // Status: requested | shown | dismissed
      if(status === 'shown') {
        StorageService.setItem('lastReview', moment())
      }
    })
  },
  // Te dirige a la store para la valoración de la app
  launchReview(){
    LaunchReview.launch()
  },
  // Comprueba si el dispositivo soporta las valoraciones
  isRatingSupported(){
    return LaunchReview.isRatingSupported()
  },

}

export default ReviewService;
