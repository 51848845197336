import store from 'Core/store/store';
import Router from 'Core/router';
import AppError from 'Core/services/errors.service';
import io from 'socket.io-client';
import AuthService from 'Auth/services/auth.service';
import StorageService from 'Core/services/storage.service';
import log from 'Core/services/log.service';
import { getModeStringValue } from 'Units/utils/mode.utils';
import unitTempUtil from 'Auth/utils/unitTemp.util';
import constants from 'Core/constant';

// MODELS
import User from 'Auth/models/User';
import { Device, Outputs } from 'Units/models/DeviceHierarchy';
import Webserver from 'Units/models/Webserver.model';
import ScheduleConf from 'Units/models/ScheduleConf.model';
import DemoService from 'Core/services/demo.service';
import Scene from 'Units/models/Scene.model';

import CONSTANTS from 'Units/constant';
import { hasProp } from 'Core/utils/validate.utils';
import { reloadPage } from 'Core/utils/utils';
// import App from 'Core/models/App';
import cloud2web from 'Units/interfaces/cloud2web.interface';
import { setLanguage } from './language.service';
import { resolveSkinsState } from 'Auth/utils/skin.utils';
// import RollbarService from './Rollbar.service';


/**
 * @type {import('socket.io-client').Socket}
 */
let userSocket = null;
/**
 * @type {string}
 */
let jwt = null;
// const baseURL = CONSTANTS.CONNECT.APP.AZCLOUD_API_URL;

let reconnectAttemps = 0;

let initialStateData = [];

const SocketService = {
  //
  // Ver si tenemos conexión al socket
  //
  hasSocketConnection() {
    if(userSocket) {
      return true;
    }

    return false;
  },


  //
  // Comenzamos a escuchar los eventos de una instalación
  //
  listenInstallation(installationID, newJWT) {
    return new Promise (async (resolve, reject) => {
        if(!userSocket) return resolve();
        await this.clearListeners();

        const user = User.query().first();

        let command = 'listen_installation';

        if(user?.admin_mode === true) {
          command = 'listen_installation_admin'
        }

        return userSocket.emit(command, installationID, async err => {
          //
          // err es el callback de respuesta. Será null si todo ha ido bien o un error en otro caso.
          //
          if(err !== null){
            switch(err._id) {
              case 'tooManyConnections':
                log.error("Error en listenInstallation");
                return reject(new AppError('tooManyConnections'));
              case 'notAuthorized':
                return reject(new AppError('notAuthorized'));
              default:
                log.error('Error en listenInstallation');
                console.error(err);
                reconnectAttemps++;
                if(reconnectAttemps <= 5) {
                  console.log("Intento reconexión", reconnectAttemps)

                  this.disconnectSocket();
                  await this.connectUserSocket(newJWT);
                  await this.listenInstallation(installationID);
                } else {
                  // Si no conseguimos reconectar limpio el usuario y redirijo al login
                  reconnectAttemps = 0; // Reiniciamos el contador de intentos
                  User.clearUser();
                  Router.push({ name: 'login', query: { reason: 'sessionExpired' } }).catch(() => {});
                  console.error('Error tratando de reconectar desde listen_installation');
                  // reject(new AppError('default'));
                }
            }
          } else {
            reconnectAttemps = 0; // Si no obtenemos error reiniciamos el contador de intentos
          }

          reconnectAttemps = 0; // Si no obtenemos error reiniciamos el contador de intentos

          return resolve();
        })

    });
  },

  //
  // Comenzamos a escuchar los eventos de una instalación
  //
  listenWebserver(webserverID, newJWT) {
    return new Promise (async (resolve, reject) => {
        if(!userSocket) return resolve();
        await this.clearListeners();

        const user = User.query().first();

        let command = 'listen_ws';

        if(user?.admin_mode === true) {
          command = 'listen_ws_admin'
        }

        return userSocket.emit(command, webserverID, async err => {
          //
          // err es el callback de respuesta. Será null si todo ha ido bien o un error en otro caso.
          //
          if(err !== null){
            switch(err._id) {
              case 'tooManyConnections':
                log.error("Error en listenWebserver");
                return reject(new AppError('tooManyConnections'));
              case 'notAuthorized':
                return reject(new AppError('notAuthorized'));
              default:
                log.error('Error en listenWebserver');
                console.error(err);
                reconnectAttemps++;
                if(reconnectAttemps <= 5) {
                  console.log("Intento reconexión", reconnectAttemps)

                  this.disconnectSocket();
                  await this.connectUserSocket(newJWT);
                  await this.listenWebserver(webserverID);
                } else {
                  // Si no conseguimos reconectar limpio el usuario y redirijo al login
                  reconnectAttemps = 0; // Reiniciamos el contador de intentos
                  User.clearUser();
                  Router.push({ name: 'login', query: { reason: 'sessionExpired' } }).catch(() => {});
                  console.error('Error tratando de reconectar desde listen_ws');
                  // reject(new AppError('default'));
                }
            }
          } else {
            reconnectAttemps = 0;
          }

          reconnectAttemps = 0;
          return resolve();
        });


        // return resolve();

    });
  },

  //
  // Comenzamos a escuchar los eventos de un webserver
  //
  listenWebserverLink(webserverID, newJWT) {
    return new Promise (async (resolve, reject) => {
        if(!userSocket) return resolve();
        await this.clearListeners();

        const command = 'listen_ws_link';

        return userSocket.emit(command, webserverID, async err => {
          //
          // err es el callback de respuesta. Será null si todo ha ido bien o un error en otro caso.
          //
          if(err !== null){
            switch(err._id) {
                // log.error("Error en listenWebserverLink");
                // return reject(new AppError('tooManyConnections'));
              case 'notAuthorized':
                return reject(new AppError('notAuthorized'));
              case 'tooManyConnections':
              default:
                log.error('Error en listenWebserverLink');
                console.error(err);
                reconnectAttemps++;
                if(reconnectAttemps <= 5) {
                  console.log("Intento reconexión", reconnectAttemps)

                  this.disconnectSocket();
                  await this.connectUserSocket(newJWT);
                  await this.listenWebserverLink(webserverID);
                } else {
                  // Si no conseguimos reconectar limpio el usuario y redirijo al login
                  reconnectAttemps = 0; // Reiniciamos el contador de intentos
                  User.clearUser();
                  Router.push({ name: 'login', query: { reason: 'sessionExpired' } }).catch(() => {});
                  console.error('Error tratando de reconectar desde listen_ws_link');
                  // reject(new AppError('default'));
                }
            }
          } else {
            reconnectAttemps = 0;
          }

          reconnectAttemps = 0;
          return resolve();
        });


        // return resolve();

    });
  },

  //
  // Dejar de escuchar los eventos de una instalación (liberamos tráfico del socket)
  //
  clearListeners() {
    return new Promise ( (resolve, reject) => {
      if(!userSocket) return resolve();
      return userSocket.emit('clear_listeners', err => {
        if(err !== null){
          console.log(err);
          return reject(new AppError('clearListeners', 'Error en clear_listeners'))
        }
        return resolve();
      });

    });
  },


  connectUserSocket(newJWT) {
    return new Promise( async (resolve, reject) => {
      //
      // Comprobamos si hay socket de usuario previamente. Si hay, nos aseguramos que el token está actualizado y no hacemos nada más.
      //
      if( userSocket ) {
        try{
          await this.updateToken(userSocket, newJWT);
          resolve(true);
          return
        } catch(err) {
          // Si ha ocurrido un error al recuperar el socket, continuamos ejecutando para instanciarlo de nuevo.
          console.log(err);
        }
      }
      //
      // Obtento el token de usuario si existe
      //
      jwt = StorageService.getItem('access_token');
      //
      // Preparamos la conexión con el socket
      //
      userSocket = io(constants.CONNECT.APP.AZCLOUD_BASE_URL,
      {
        path: constants.CONNECT.APP.AZCLOUD_SOCKET_PATH,
        secure: true,
        query: {
          jwt,
        },
        transports: ['websocket'],
        transportOptions: {
          polling: {
            extraHeaders: {
              'Authorization': `Bearer ${jwt}`
            }
          }
        }
      });
      //
      // Capturo cualquier evento que me venga por el socket y lo muestro
      //
      userSocket.onAny(async (event, ...args) => {
        // console.log(`Evento ${JSON.stringify(event)}`)
        // console.log(`Args: ${JSON.stringify(args)}`)
        //
        // Los datos recibidos del evento
        //
        const receivedData = args[0];
        const dataToUpdate = {};
        let eventProps;
        if(typeof event === 'string'){
          eventProps = event.split('.');
        }
        const eventName = eventProps[0];
        // const eventDeviceMac = (eventProps[1] === undefined) ? null : eventProps[1];
        const eventDeviceID = eventProps[2] === undefined ? null : eventProps[2];
        const eventType = eventProps[1] === undefined ? null : eventProps[1];

        switch(eventName){
          case 'USERS':
            const user = User.query().first();
            //
            //  Asigno el parámetro y valor recibidos
            //
            if(receivedData.param === 'units') {
                //
                // Si el valor modificado corresponde a 'units', almaceno el valor según la configuración del usuario
                // guardaré 'celsius' o 'fah'. Si la ruta incluye 'installation' recargamos página
                //
                receivedData.value = unitTempUtil.unitTotempunit(receivedData.value);
                //
                // CASUÍSTICA: se actualiza el usuario y tenemos otra ventana con la instalación abierta
                //
                this.refreshListeners();
            }
            let actualSkin = null;
            let hasSkinsAvail = false;

            if(receivedData?.param === 'skin') {
              const skinAvailIds = receivedData?.value?.avail_skin_ids;
              const currentSkinId = receivedData?.value?.current_skin_id;
              // Si nos llega una actualización a nivel de usuario de skin enabled lo agregamos
              if(receivedData?.value?.enabled !== undefined) {
                dataToUpdate.isSkinEnabled = receivedData.value.enabled;
              }

              const skinState = await resolveSkinsState({skinAvailIds, currentSkinId, userId: user?.id, isSkinEnabled: dataToUpdate.isSkinEnabled});
              actualSkin = skinState.actualSkin;
              hasSkinsAvail = skinState.hasSkinsAvail;
              //
              // Si el valor modificado es el skin, lo cambio también a nivel global de la aplicación
              //
              receivedData.value = actualSkin;
              dataToUpdate.hasSkinsAvail = hasSkinsAvail;
            }

            //
            //  Actualizamos el dato para el modelo
            //
            dataToUpdate[receivedData.param] = receivedData.value;
            //
            // Si el valor modificado es el idioma, lo cambio también a nivel global de la aplicación
            //
            if(receivedData.param === 'lang') setLanguage(receivedData.value);
            //
            // Actualizo el modelo del usuario en el front
            //
            await User.update({
              where: user.id,
              data: dataToUpdate
            });
            break;
          case 'DEVICE_STATE_END':
            // document.dispatchEvent(new CustomEvent('deviceStateEnd', {detail: initialStateData}));
            await this.handlerEventDeviceStateEnd(eventName, receivedData);
            break;
          case 'DEVICE_STATE':
            initialStateData.push(receivedData);
            // this.handlerReceivedData(eventName, receivedData, false);
            break;
          case 'DEVICES_UPDATES':
            await this.handlerEventDeviceUpdate(eventName, receivedData);
            break;
          case 'WEBSERVER_UPDATES':
            await this.handlerWsReceivedData(eventName, receivedData);
            break;
          case 'DEVICES':
            // console.log(`Evento: ${eventName} tipo ${eventType} id: ${eventDeviceID}`);
            if(eventType === 'ws-finish-discovery'){
              await this.handlerEventDetectSystem(event,receivedData);
            }
            if(eventType === 'device-change-link-state'){
              // console.log('handlerEventChangeLinkState')
              await this.handlerEventChangeLinkState(event,receivedData);
            }
            if(eventType === 'finished-ota-ws'){
              console.log('handlerEventFinishOTA')
              await this.handlerEventFinishOTA(event,receivedData);
            }
            if(eventType === 'device-change-config') {
              await this.handlerEventDeviceUpdate(eventName, receivedData);
            }
            break;
          case 'INSTALLATIONS':
            console.log(`Evento: ${eventName} tipo ${eventType} id: ${eventDeviceID}`);

            //
            // Si un usuario es invitado a una instalación, actualizamos sus instalaciones pendientes
            //
            if(eventType === 'user-invited-installations') {
              console.log(`Invitación a instalación ${receivedData}`);
              const user = User.query().first();
              await User.update({
                where: receivedData.user_id,
                data: {
                  pendingInstallations: user.pendingInstallations +1,
                }
              });
            }

            //
            // Si un usuario ha confirmado la invitación a una instalación, recargamos
            // la página para tenerlo actualizado si tiene el modal de invitación visible
            //
            if(eventType === 'user-confirmed-installation') {
              console.log(`Se ha confirmado la invitación a la instalación ${receivedData}`);
              const customEvt = new CustomEvent('userConfirmedInstallation', {
                cancelable: false,
                detail: {
                  installation_id: receivedData.installation_id
                }
              });

              document.dispatchEvent(customEvt);
            }

            //
            // Si se mueven zonas entre grupos en la instalación, recargamos la página para tenerlo actualizado
            //
            if(eventType === 'devices-moved-group') {
              console.log(`Moviendo dispositivos entre grupos en ${receivedData.installation_id}`);

              // Al mover dispositivos de un grupo a otro recargamos la página
              // Router.go();
              const customEvt = new CustomEvent('devicesMovedGroup', {
                cancelable: true,
                detail: {
                  installation_id: receivedData.installation_id
                }
              });

              document.dispatchEvent(customEvt);
            }

            if(eventType === 'user-edit-permission-installation') {
              console.log(`Permisos de ${receivedData.user_id} actualizados en ${receivedData.installation_id}`);
              const user = User.query().first();
              // Al cambiarnos los permisos dentro de una instalación recargamos
              if(user.id === receivedData.user_id){
                reloadPage();
              }
              // const customEvt = new CustomEvent('userEditPermission', {
              //   cancelable: true,
              //   detail: {
              //     user_id: receivedData.user_id,
              //     installation_id: receivedData.installation_id
              //   }
              // });

              // document.dispatchEvent(customEvt);
            }

             //
            // Si se mueven zonas entre grupos en la instalación, recargamos la página para tenerlo actualizado
            //
            if(eventType === 'installation-updated-discovery') {
              console.log(`Detectar sistemas lanzado ${receivedData.installation_id}. Refrescando página`);
              // Al detectar sistemas recargamos para obtener la nueva topología
              reloadPage();
            }

            //
            // Si un usuario es eliminado de la instalación en la que está es redirigido a main
            //
            if(eventType === 'user-deleted-installation') {
              console.log(`Usuario eliminado de la instalación ${receivedData.installation_id}`);
              console.log(`Se ha abandonado la instalación ${receivedData}`);
              const customEvt = new CustomEvent('userDeletedInstallation', {
                cancelable: false,
                detail: {
                  installation_id: receivedData.installation_id
                }
              });

              document.dispatchEvent(customEvt);
              Router.push({path: '/', query: {reason: 'userNotIncluded'}}).catch(() => {});
            }

            //
            // Si una escena cambia su estado de ejecución
            //
            if(eventType === 'started-exec-scene') {
              console.log(`Iniciada escena ${receivedData.scene_id}`);
              await Scene.update({
                data: {
                  id: receivedData.scene_id,
                  running: true
                }
              })
            }

            if(eventType === 'finished-exec-scene') {
              console.log(`Finalizada escena ${receivedData.scene_id}`);
              await Scene.update({
                data: {
                  id: receivedData.scene_id,
                  running: false
                }
              })
              document.dispatchEvent(new CustomEvent('finishScene', { detail: receivedData.scene_id }));
            }

            break;
          default:
        }
      })


      userSocket.on('connect', async () => {
        // console.log('Conectado al socket');
        // log.success(`Usuario conectado al socket`); // Se muestra el mensaje en el modelo
        // RollbarService.info("Connected to socket");
        reconnectAttemps = 0; // Si no obtenemos error reiniciamos contador de intentos
        resolve(true);
      });

      userSocket.on('connect_error', async err => {
        console.log("CONNECT ERROR",err.description);
        // RollbarService.error(`Error en conexión ${err.name},${err.message}`)
        // // Intento de reconexión
        // reconnectAttemps++;
        // if(reconnectAttemps <= 5) {
        //   console.log("Intento reiniciar la conexión. Attemps",reconnectAttemps);
        //   RollbarService.error(`Reintento de conexión`, reconnectAttemps);

        //   await this.updateToken(userSocket);
        //   userSocket.connect();
        //   await this.refreshListeners();

        // } else {
        //   // Si no conseguimos reconectar limpiamos los intentos y lanzamos error
        //   reconnectAttemps = 0;
        //   RollbarService.error(`Agotados intentos de reconexión`)

        //   reject(err);
        // }

        // reject(err);
      })

      /**
      * El evento 'disconnect' me saca fuera del socket
      * Puede ser porque nos han cerrado todas las sesiones o porque nos han eliminado el usuario
      * Limpio
      */
       userSocket.on('disconnect', async reason => {
        if (!window.navigator.onLine) return;

        if (reason === 'io server disconnect') {
          try {
            await this.updateToken(userSocket);
            userSocket.connect();
            await this.refreshListeners();

          } catch ( error ) {
            console.log('ERROR en disconect', error);
            //
            // Si nos han desconectado limpiamos el usuario y redirigimos al login
            //
            User.clearUser();
            Router.push({ name: 'login', query: { reason: 'sessionExpired' } }).catch(() => {});
          }
        }
      });

      userSocket.io.on('error', async err => {
        console.log('Desconexion socket error', err, err.description, err.type, window.navigator);
        // RollbarService.error('Desconectado de WS por error');
        // let notConnectedToInternet = false
        // Check if mobile has output to interntet
        // if (constants.IS_MOBILE) {
        //   // eslint-disable-next-line no-undef
        //   await WifiWizard2.isConnectedToInternet()
        //     .then(() => {
        //       console.log('Hay internet, intento la reconexión del socket')
        //     })
        //     .catch( error => {
        //       console.log(error)
        //       console.log('No hay internet, no intento la reconexión del socket', error)
        //       notConnectedToInternet = error === 'NOT_CONNECTED_TO_INTERNET'
        //     })
        // }
        if (!window.navigator.onLine) return;
        try {
          await this.updateToken(userSocket);
        } catch ( error ) {
          console.log(error)
          //
          // Si el backend está disponible
          //
          if (error?.name !== 'backendDown') {
            //
            // Si nos han desconectado limpiamos el usuario y redirigimos al login
            //
            this.disconnectSocket();
            User.clearUser();
            Router.push({ name: 'login', query: { reason: 'sessionExpired' } }).catch(() => {});
          }
          reject(new AppError('socketConnectError'));
        }
      });

      userSocket.io.on('reconnect', async attempt => {
        console.log(`Reconectado tras ${attempt} intentos`);
        await this.refreshListeners();
      })

      userSocket.on('auth', async (event, callback) => {

        // console.log(`Recibido evento: ${JSON.stringify(event)}`);
        if(event === 'authenticate') callback(StorageService.getItem('access_token'));
          // console.log('Respondo con token válido');
      });
    });

  },
  /**
   * Desconecta los sockets del usuario
   */
  disconnectSocket() {
    console.log('DISCONNECT SOCKET lanzado');
    if(userSocket === null) {
      return this;
    }
    userSocket.close();
    // userSocket.removeAllListeners();

    userSocket = null;

    return this;
  },



  /**
   * Recupera la información de todos los Devices de una instalación actualizada
   * NOTA: Comprueba la ruta en la que está por si es necesario actualizarla
   */
  async refreshListeners() {
    return new Promise (async (resolve, reject) => {

      //
      // Al volver de segundo plano el refreshListeners puede quedarse pillado,
      // Por ello, si no devolvemos nada en 5 segundos, lo rechazamos
      //
      let timeout = null;
      //
      //
      // Si estoy dentro de las vistas de una instalación refresco los listeners
      //
      //
      // Limpiamos listeners
      //
      try {
        await this.clearListeners();
      } catch(err) {
        console.log(err);
        if(timeout) clearTimeout(timeout);
        return reject(err);
      }

      if(Router.currentRoute.value.path.includes('installation')
        && Router.currentRoute.value.params.installationID !== undefined) {
        timeout = setTimeout(() => {
          console.log('Timeout en refreshListeners');
          return reject(new AppError('refreshListeners', 'Timeout en refreshListeners'));
        }, 5000);
        //
        // Reconectamos para obtener la información actualizada
        //
        try {
          await this.listenInstallation(Router.currentRoute.value.params.installationID);
          if(timeout) clearTimeout(timeout);
          return resolve(true);
        } catch(err) {
          console.log(err);
          if(timeout) clearTimeout(timeout);
          return reject(err);
        }

      } else if(Router.currentRoute.value.path.includes('ws')
              && Router.currentRoute.value.params.webserverID !== undefined){
        timeout = setTimeout(() => {
          console.log('Timeout en refreshListeners');
          return reject(new AppError('refreshListeners', 'Timeout en refreshListeners'));
        }, 5000);
        //
        // Reconectamos para obtener la información actualizada
        //
        try {
          await this.listenWebserver(Router.currentRoute.value.params.webserverID);
          if(timeout) clearTimeout(timeout);
          resolve(true);
        } catch(err) {
          console.log(err);
          if(timeout) clearTimeout(timeout);
          reject(err);
        }
      }

      if(timeout) clearTimeout(timeout);

      return resolve(false);
    });

  },

  async handlerEventDeviceStateEnd(eventName, event){
    // console.log(`Evento: ${JSON.stringify(event)}`);

    await this.populateDevicesStateInstallation();

    store.dispatch('setLoadingStatus', false);

    document.dispatchEvent(new CustomEvent('deviceStateEnd'));

  },

  async handlerEventDeviceUpdate(eventName, event) {
    // console.log(`Evento: ${JSON.stringify(event)}`);

    const deviceUpdateData = await this.handlerReceivedData(eventName, event)

    // console.log("DEVICE_UPDATE", deviceUpdateData)

    if(deviceUpdateData.device) {
      // Se lanza evento para escuchar cambio de etapas en configuración de salidas por cloud. Este evento se lanza al escuchar un cambio en el device y necesitamos escuchar el siguiente cambio.
      if(event.change.adv_conf?.device_cooling_stages_conf || event.change.adv_conf?.device_heat_stages_conf) {
        document.dispatchEvent(new CustomEvent('eventDeviceUpdate_device', {detail: event}));
      }

      document.dispatchEvent(new CustomEvent('deviceUpdates', {detail: event}));

      await Device.insertOrUpdate({data: deviceUpdateData.device});
    }

    if(deviceUpdateData.scheduleConfData) {
      await ScheduleConf.insertOrUpdate({data: deviceUpdateData.scheduleConfData})
    }

    if(deviceUpdateData.output) {
      await Outputs.insertOrUpdate({data: deviceUpdateData.output});
    }

  },

  /**
   * Lanzo evento del estado del webserver para controlar en la vista si está detectando sistemas, actualizando o completado
   */
  async handlerEventChangeLinkState(event, receivedData) {
    if (receivedData.state === 'first_discovery_active') {
      // Launch firstDiscoveryActive event
      document.dispatchEvent(new CustomEvent('firstDiscoveryActive'));
    } else if (receivedData.state === 'upgrade_active') {
      // Launch upgradeActive event
      document.dispatchEvent(new CustomEvent('upgradeActive'));
    } else if (receivedData.state === 'link_end') {
      // Launch linkEnd event
      document.dispatchEvent(new CustomEvent('linkEnd'));
    }

  },

  /**
   * Emito evento de fin de la actualización
   */
  async handlerEventFinishOTA(event, receivedData) {
    console.log(`Evento: ${JSON.stringify(event)} datos: ${receivedData}`);
    // Launch finishOta event
    document.dispatchEvent(new CustomEvent('finishOTA'));
  },

  async handlerEventDetectSystem(event, receivedData) {
    const webserverID = receivedData.wsConfig.mac;
    const ws = Webserver.query().find(webserverID);
    const data = receivedData;
    // const installationID = ws.installation_id;
    //
    // Actualizar el modelo de datos con los cambios
    //
    // await Webserver.getWebserverStatus(installationID, webserverID, true);
    //
    // Actualizo el estado del webserver para ocultar la carga
    //
    document.dispatchEvent(new CustomEvent('finishDiscovery', {detail: data}));
    await ws.setParam('isDetecting', false);
  },

  async handlerWsReceivedData(eventName, event) {
    const webserverID = event.ws_id;

    if(event.change) {
      const receivedData = {...event.change};
      const output = await cloud2web.getWebserverData(undefined, webserverID, receivedData, false);

      const data = output.data;
      // console.log("updating WS", data);
      await Webserver.insertOrUpdate({data});

      document.dispatchEvent(new CustomEvent('webserverUpdates', {detail: data}));

    }


  },

  // handlerDeviceStateData(event) {
  //   const deviceStateData = {};
  //   const deviceID = event.device_id;
  //   const receiveData = event.status !== undefined ? event.status : {};
  //   const device = {id: deviceID};
  //   // console.log(`receiveData: ${JSON.stringify(receiveData)}`);

  //   const user = User.query().first();
  //   const userUnits = user ? user.units : 'celsius';
  //   //
  //   // En DEVICE_STATE, si es sistema, recibo la configuración de programaciones
  //   //
  //   //
  //   // En DEVICE_STATE, si es sistema, recibo la configuración de programaciones
  //   //
  //   if(event.sched_conf) {
  //     const scheduleConf = event.sched_conf !== undefined ? event.sched_conf : {}
  //     const scheduleConfData = {};
  //     //
  //     // Cargo los datos de sched_conf
  //     //

  //     Object.keys(scheduleConf).forEach( param => {

  //       if(scheduleConf[param] === null){
  //         scheduleConfData[param] = null;
  //       } else if (hasProp(scheduleConf[param], 'celsius')){
  //         scheduleConfData[param] = scheduleConf[param][userUnits];
  //       } else {
  //         scheduleConfData[param] = scheduleConf[param];
  //       }
  //     });
  //     scheduleConfData.id = event.device_id;
  //     scheduleConfData.device_id = event.device_id;
  //     //
  //     // Actualizo el modelo con los datos recuperados
  //     //
  //     deviceStateData.scheduleConfData = scheduleConfData;
  //   }

  //   // Comprobamos si la primera key coincide con una representación de tipo "output" para el modelo "az_outputs"
  //   const firstKey = Object.keys(receiveData)[0]
  //   // console.log(firstKey,String(firstKey).match(CONSTANTS.OUTPUTS_PARAMS_REGEX))
  //   if(firstKey && String(firstKey).match(CONSTANTS.OUTPUTS_PARAMS_REGEX)) {
  //     const output = Outputs.find(event.device_id)
  //     Object.keys(receiveData).forEach( key => {
  //       const outputID = receiveData[key]?.output_id
  //       // console.log(outputID, output)
  //       if (outputID) {
  //         const index = output?.outputs?.findIndex(out => out.output_id === outputID)
  //         if(index !== undefined) {
  //           output.outputs[index] = receiveData[key]
  //         }
  //       }
  //     })

  //     deviceStateData.output = output;
  //   }

  //   Object.keys(receiveData).forEach( param => {
  //     if(receiveData[param] !== undefined){
  //       if(receiveData[param] === null) {
  //         // el valor null es un valor válido, que implica quitar de la vista
  //         device[param] = receiveData[param];
  //         return;
  //       }

  //       //
  //       // Comprobamos si el parámetro es de temp para guardar el valor según unidad del usuario
  //       //
  //       if(hasProp(receiveData[param], 'celsius')){
  //         device[param] = receiveData[param][userUnits]
  //       //
  //       // Tratamiento del modo para parsear a nuestro modelo
  //       //
  //       } else if( param === 'mode') {
  //         device.mode = getModeStringValue(receiveData[param]);
  //       } else if( param === 'mode_available'){
  //         device.mode_available = receiveData.mode_available.map( modeID => getModeStringValue(modeID))
  //         //
  //         // Si el parámetro contiene un array comprobamos si es o no de temp y lo guardamos en consecuencia
  //         //
  //       } else if( Array.isArray(receiveData[param]) && receiveData[param].length > 0){
  //         const arrayData = [];
  //         receiveData[param].forEach( value => {
  //           if(hasProp(value, 'celsius')) {
  //             arrayData.push(value[userUnits])
  //           } else {
  //             arrayData.push(value);
  //           }
  //         })
  //         device[param] = arrayData;
  //       } else {
  //         device[param] = receiveData[param];
  //       }
  //     }
  //   });

  //   //
  //   // Actualizo estdo loadingData y modificando del dispositivo
  //   //
  //   device.loadedData = true;
  //   device.isChanging = false;
  //   device.isChangingMode = false;

  //   deviceStateData.device = device

  //   return deviceStateData;
  // },

  async handlerReceivedData(eventName, event, dispatchUpdatedParam = true) {
    return new Promise(resolve => {
      // console.log(`Evento: ${eventName}`);

      const deviceID = event.device_id;
      let status = {};
      let config = {};
      let data = {};
      let advancedConf = {};
      let userConf = {};
      const deviceUpdateData = {};

      if(event.change !== undefined){
        data = event.change !== undefined ? event.change : {};
        status = event.change.status !== undefined ? event.change.status : {};
        config = event.change.config !== undefined ? event.change.config : {};
        advancedConf = event.change.adv_conf !== undefined ? event.change.adv_conf : {};
        userConf = event.change.user_conf !== undefined ? event.change.user_conf : {};
      } else {
        config = event.config !== undefined ? event.config : {};
        advancedConf = event.adv_conf !== undefined ? event.adv_conf : {};
        userConf = event.user_conf !== undefined ? event.user_conf : {};
        status = event.status !== undefined ? event.status : {};
      }

      if (dispatchUpdatedParam && this.isSetpoint(status)) {
        document.dispatchEvent(new CustomEvent('setpointUpdated'));
      }

      const receiveData = {...status, ...config, ...advancedConf, ...userConf, ...data}
      // log.info(`receiveData: ${JSON.stringify(receiveData)}`);

      const user = User.query().first();
      const userUnits = user ? user.units : 'celsius';
      //
      // En DEVICE_STATE, si es sistema, recibo la configuración de programaciones
      //
      if(eventName === 'DEVICE_STATE' && event.sched_conf) {
        const scheduleConf = event.sched_conf !== undefined ? event.sched_conf : {}
        const scheduleConfData = {};
        //
        // Cargo los datos de sched_conf
        //

        Object.keys(scheduleConf).forEach( param => {

          if(scheduleConf[param] === null){
            scheduleConfData[param] = null;
          } else if (hasProp(scheduleConf[param], 'celsius')){
            scheduleConfData[param] = scheduleConf[param][userUnits];
          } else {
            scheduleConfData[param] = scheduleConf[param];
          }
        });
        scheduleConfData.id = event.device_id;
        scheduleConfData.device_id = event.device_id;
        //
        // Actualizo el modelo con los datos recuperados
        //
        // await ScheduleConf.insert({data: scheduleConfData})
        deviceUpdateData.scheduleConfData = scheduleConfData;
      }

      // Comprobamos si la primera key coincide con una representación de tipo "output" para el modelo "az_outputs"
      const firstKey = Object.keys(receiveData)[0]
      // console.log(firstKey,String(firstKey).match(CONSTANTS.OUTPUTS_PARAMS_REGEX))
      if(firstKey && String(firstKey).match(CONSTANTS.OUTPUTS_PARAMS_REGEX)) {
        const output = Outputs.find(event.device_id)
        Object.keys(receiveData).forEach( key => {
          const outputID = receiveData[key]?.output_id;
          const outputType = receiveData[key]?.type;
          if (outputID) {
            const index = output?.outputs?.findIndex(out => out.output_id === outputID && out.type === outputType);
            if(index !== undefined) {
              output.outputs[index] = receiveData[key];
            }
          }
        })
        // console.log('Actualiza: ',output)
        deviceUpdateData.output = output;
        // await Outputs.insertOrUpdate({data: output})
        return resolve(deviceUpdateData);
      }

      const device = Device.query().whereId(deviceID).first();
      if(!device) return resolve(deviceUpdateData);

      if(dispatchUpdatedParam) document.dispatchEvent(new CustomEvent('updateDeviceGroup', {detail: device?.group_id}));

      Object.keys(receiveData).forEach( param => {
        if(receiveData[param] !== undefined){
          if(receiveData[param] === null) {
            // el valor null es un valor válido, que implica quitar de la vista
            device[param] = receiveData[param];
            return;
          }

          //
          // Comprobamos si el parámetro es de temp para guardar el valor según unidad del usuario
          //
          if(hasProp(receiveData[param], 'celsius')){
            device[param] = receiveData[param][userUnits]
            if(dispatchUpdatedParam) this.dispatchCustomEventUpdatedParam(param, receiveData, userUnits)
          //
          // Tratamiento del modo para parsear a nuestro modelo
          //
          } else if( param === 'mode') {
            device.mode = getModeStringValue(receiveData[param]);
            if(dispatchUpdatedParam) this.dispatchCustomEventUpdatedParam(param, receiveData)
          } else if( param === 'mode_available'){
            device.mode_available = receiveData.mode_available.map( modeID => getModeStringValue(modeID))
            //
            // Si el parámetro contiene un array comprobamos si es o no de temp y lo guardamos en consecuencia
            //
            if(dispatchUpdatedParam) this.dispatchCustomEventUpdatedParam(param, receiveData)
          } else if( Array.isArray(receiveData[param]) && receiveData[param].length > 0){
            const arrayData = [];
            receiveData[param].forEach( value => {
              if(hasProp(value, 'celsius')) {
                arrayData.push(value[userUnits])
              } else {
                arrayData.push(value);
              }
            })
            device[param] = arrayData;
            if(dispatchUpdatedParam) this.dispatchCustomEventUpdatedParam(param, receiveData)
          } else {
            device[param] = receiveData[param];
            if(dispatchUpdatedParam) this.dispatchCustomEventUpdatedParam(param, receiveData)
          }
        }
      });

      //
      // Actualizo estdo loadingData y modificando del dispositivo
      //
      if(device.loadedData !== undefined && device.loadedData !== null && device.loadedData === false){
        device.loadedData = true;
      }
      if(device.isChanging !== undefined && device.isChanging !== null && device.isChanging === true){
        device.isChanging = false;
      }
      if(device.isChangingMode !== undefined && device.isChangingMode !== null && device.isChangingMode === true){
        device.isChangingMode = false;
      }



      //
      // Actualizo los datos del dispositivo
      //
      // await Device.insertOrUpdate({data: device});
      deviceUpdateData.device = device

      // Emito evento de fin de actualización del Modelo
      // console.log('emito deviceModelUpdated')
      // document.dispatchEvent(new CustomEvent('deviceModelUpdated'));

      // console.log("Actualizando", deviceUpdateData);

      return resolve(deviceUpdateData);
    });


  },

  async updateModelData(devicesUpdateData, dispatchEvents = true) {
    const promises = [];
    const devices = [];
    const schedulesConf = [];
    const outputs = [];

    devicesUpdateData.forEach(deviceData => {
      // const deviceStateData = this.handlerDeviceStateData(deviceData);
      promises.push(this.handlerReceivedData('DEVICE_STATE', deviceData, dispatchEvents));

    });

    await Promise.all(promises).then(deviceStatesData => {
      deviceStatesData.forEach(data => {
        if(data.device) {
          devices.push(data.device);
        }

        if(data.scheduleConfData) {
          schedulesConf.push(data.scheduleConfData)
        }

        if(data.output) {
          outputs.push(data.output);
        }
      });

    });

    if(devices.length > 0) {
      await Device.insertOrUpdate({data: devices});
    }

    if(schedulesConf.length > 0) {
      await ScheduleConf.insertOrUpdate({data: schedulesConf})
    }

    if(outputs.length > 0) {
      await Outputs.insertOrUpdate({data: outputs});
    }
  },

  /**
   * Maneja el estado inicial de todos los dispositivos en paralelo
   * (NOTA: deshabilitamos el dispatch de eventos custom de updates)
   */
  async populateDevicesStateInstallation() {

    await this.updateModelData(initialStateData, false);

    //
    // inicializamos StateData
    //
    initialStateData = [];

  },

  /**
   * Inicializa el estado de la instalación en demo
   *
   * @param {string} installationID
   */
  async populateDevicesStatusDemoInstallation(installationID) {
    const deviceStatusData = await DemoService.getDevices(installationID);

    await this.updateModelData(deviceStatusData, false);
  },

  /**
   * Refresca el token y los actualiza en el socket.io
   *
   * @param {Object} socket - Objeto de socket.io
   */
  async updateToken(socket, newJWT) {

    if(newJWT === undefined) {
      jwt = await AuthService.refreshToken();
    } else {
      jwt = newJWT;
    }


    if(socket && socket.io && socket.io.opts && socket.io.opts.query) {
      socket.io.opts.query.jwt = jwt;
    }
    if(socket && socket.io && socket.io.opts && socket.io.opts.transportOptions && socket.io.opts.transportOptions.polling && socket.io.opts.transportOptions.polling.extraHeaders) {
      // eslint-disable-next-line dot-notation
      socket.io.opts.transportOptions.polling.extraHeaders['Authorization'] = `Bearer ${jwt}`;
    }
  },

  isSetpoint(status) {
    return ( hasProp(status, 'setpoint_air_cool') || hasProp(status, 'setpoint_air_heat') || hasProp(status, 'setpoint_air_auto') || hasProp(status, 'airsetpoint_air_vent') || hasProp(status, 'airsetpoint_air_stop') || hasProp(status, 'airsetpoint_air_dry') || hasProp(status, 'airsetpoint_air_emerheat') )
  },

  // isParam(param) {
  //   // console.log('isParam', param)
  //   return (( param === 'm010_min_voltage_conf') || (param === 'm010_max_voltage_conf') || (param === 'max_cool_battery_temperature_conf') || (param === 'min_heat_battery_temperature_conf') || (param === 'max_heat_setpoint') || (param === 'min_cool_setpoint') || (param === 'offset_env_heat_conf') || (param === 'offset_env_cool_conf'))
  // },

  dispatchCustomEventUpdatedParam(param, receiveData, units){
    // Emito evento de parámetro actualizado por sockets
    // if (this.isParam(param)) {
      if (units) {
        document.dispatchEvent(new CustomEvent('paramUpdated', { detail: { param, value: receiveData[param][units] } } ));
      } else {
        document.dispatchEvent(new CustomEvent('paramUpdated', { detail: { param, value: receiveData[param] } } ));
      }
    // }
  },
}

export default SocketService;
